.inputKeyword_line{
    width: 100%;
    line-height: 36px;
    border: 1px solid #CED6E0;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 36px;
}
.inputKeyword_line:focus{
    outline: none;
    border: 1px solid #139DD9;
}
.inputKeyword svg{
    position: absolute;
    top: 10px;
    right: 13.5px;
}
.searchFindBlock{
    display: flex;
    padding: 10px;
}
.inputKeyword{
    width: 1020px;
    margin-right: 12px;
    position: relative;
    margin-bottom: 28px;
    
}
.findStart{
    width: 106px;
    height: 40px;
    background: #171725;
    border-radius: 4px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-left: 8px;
    cursor: pointer;
}
.findStart svg{
    margin-left: 16px;
    margin-right: 8px;
}
.findStart a{
    color: #FFFFFF;
}
.searchFilterBlock{
    display: flex;
}
.searchFilterBlock_icon{
    margin-right: 15.22px;
    margin-top: 6px;
}