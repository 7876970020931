.blockWrap{
    position: relative;
    width: 780px;
    height: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 15px;
    padding: 15px 0;
}
.infoBlock {
    width: 100%;
    height: auto;
    /* border: 1px solid #E2E2EA; */
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    /* margin: 20px 0 0 0; */
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.itemsListContent{
    display: flex;
    flex-flow: column nowrap;
    /* gap: 15px; */
    width: 100%;
}
.infoBlockTitle{
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0 20px;
}