.search{
    padding: 0;
    background-color:#FAFAFB;
    margin-bottom: 24px;
}
.searchWrap_title{
    margin-bottom: 32px;
    position: relative;
}
.searchTitle{
    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43px;
    color: #000000;
}
.inputKeyword_line{
    width: 100%;
    line-height: 36px;
    border: 1px solid #CED6E0;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 36px;
}
.inputKeyword_line:focus{
    outline: none;
    border: 1px solid #139DD9;
}
.inputKeyword svg{
    position: absolute;
    top: 10px;
    right: 13.5px;
}
.searchFindBlock{
    display: flex;
    padding: 10px;
}
.inputKeyword{
    width: 1020px;
    margin-right: 12px;
    position: relative;
    margin-bottom: 28px;
    
}
.findStart{
    width: 106px;
    height: 40px;
    background: #171725;
    border-radius: 4px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-left: 8px;
    cursor: pointer;
}
.findStart svg{
    margin-left: 16px;
    margin-right: 8px;
}
.findStart a{
    color: #FFFFFF;
}
.searchFilterBlock{
    display: flex;
}
.searchFilterBlock_icon{
    margin-right: 15.22px;
    margin-top: 6px;
}
.searchFilterBlock_status{
    /*width: 57px;*/
    /*height: 32px;*/
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #696974;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    cursor: pointer;
    margin-right: 8px;
}
.searchFilterBlock_status_dop{
    margin-left: 8px;
    padding-left: 16px;
    border-left: 1px dashed #E2E2EA;

}
.searchFilterBlock_status_select{
    /*width: 57px;*/
    height: 32px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;
    appearance: none;
    padding-left: 8px;
}
.badgesWrapLine{
    margin-top: 24px;
    display: flex;
    border-top: 1px dashed #E2E2EA;
    padding-top: 12px;
    padding-bottom: 12px;
    flex-wrap: wrap;
}
.badgeElem{
    width: auto;
    padding: 4px 12px;
    background: #FAFAFB;
    border-radius: 100px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #696974;
    cursor: pointer;
    display: flex;
    align-items: center;
    display: flex;
    margin-right: 2px;
    margin-bottom: 2px;
}
.badgeElem_title{
    width: auto;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.badgeElem svg{
    margin-left: 11.33px;
}
.countStatus{
    border-left: 1px dashed #E2E2EA;
    padding-left: 6.5px;
    padding-right: 6.5px;
    margin-left: 6.5px;
}
.bottomSection{
    background-color: #FFFFFF;
    padding: 24px;
    border-radius: 0 0 8px 8px;
}

/* BADJEs */

.badgeElem{
    width: auto;
    padding: 2px 8px;
    background: #FAFAFB;
    border-radius: 100px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #92929D;
    cursor: default;
    display: flex;
    align-items: center;
    display: flex;
    margin: 0 2px 2px 0;
}
.badgeElem:hover{
    background-color: rgba(6, 100, 141, 0.1);
    color: #06648D;
    transition: .2s
}
.badgeElem:hover .badgeElem_title{
    color: #06648D;
    transition: .2s
}
.badgeElem_title{
    width: auto;
    max-width: 350px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
}
.closeSvg{
    cursor: pointer;
}
.badgeElem svg{
    margin-left: 11.33px;
}
.badgeElem:hover .svgIcon{
    transition: .2s;
    fill: #06648D;
}
.countStatus{
    border-left: 1px dashed #E2E2EA;
    padding-left: 6.5px;
    padding-right: 6.5px;
    margin-left: 6.5px;
}

.setupFilterWrap {
    width: 100%;
    height: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

/* .setupFilterWrap div {
    text-decoration: underline;
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-right: 40px;
    display: inline;
} */

.filterReset {
    color: #FFA047;
    cursor: pointer;
    text-decoration: underline;
}

.filterActive {
    color:#57606F;
    cursor: pointer;
}

.filterUnActive {
    color:#E2E2EA;
    cursor: not-allowed;
}
.filterSort{
    display: flex;
    width: 400px;
    flex-direction: row-reverse;
}

.assetItemType{
    width: 340px;
    /*border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;*/
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #696974;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 8px;
    margin-top: 14px;
}