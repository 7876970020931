@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

/* a {
    text-decoration: none;
}
* {
    font-family: 'Segoe UI';
    font-size: 14px;
} */

.objItem{
    width: 1176px;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 5;
    align-self: stretch;
    flex-grow: 0;
    padding: 32px;
    margin: 0 auto;
    margin-bottom: 16px;
    display: flex;
}
.objItem a{
    color: inherit;
}
.leftBlock {
    width: 64px;
    height: auto;
    padding-right: 24px;
    position: relative;
}
.avatar {
    width: 64px;
    height: 64px;
    border-radius: 8px;
    overflow: hidden;
    background-color: black;
    /* opacity: .7; */
    /* cursor: pointer; */
    position: relative;
}
.avatar img {
    width: 100%;
    height: 100%;
    opacity: .9;
    object-fit: cover;
}
.avatarText{
    position: absolute;
    width: 64px;
    height: 64px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #ffffff;
    z-index:999;
}
.unactive{
    width: 64px;
    height: 64px;
    overflow: hidden;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50.09 50'%3e%3crect width='50' height='50' fill='%23fff'/%3e%3cpolygon points='0.09 18.19 35.03 2.78 35.03 16.68 0.09 31.96 0.09 18.19' fill='%23139dd9'/%3e%3cpolygon points='37.4 6.06 50.03 0 50.09 14.4 37.4 20.21 37.4 6.06' fill='%23fcdc16'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
}
.sumFoto {
    width: 64px;
    height: 64px;
    border-radius: 8px;
    font-family: 'Roboto';
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content:center;
    color: #FFFFFF;
    background-color: rgba(23, 23, 37,.5);
    position: absolute;
    z-index: 99;
    top: 0px;
    left: 0px;
    cursor: pointer;
}
.centralBlock{
    width: 728px;
    border-right: 1px dashed #E2E2EA;
    padding-right: 24px;
}
.bottomBlock{
    display: flex;
    flex-direction: column;
    position: relative;
}
.topBlock{
    width: 100%;
    border-bottom: 1px dashed #E2E2EA;
    margin-bottom: 8px;
    padding-bottom: 10px;
}

.objIdAndType{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 14.5px;
}
.objType{
    color: #696974;
    margin: 24px 0 0px 0;
}
.objType span{
    font-weight: 600;
}
.objIdAndType_id{
    font-size: 14px;
    line-height: 19px;
    color: #696974;
    margin: 0 28px 0 0;
    cursor:pointer;
}
.yourObjBadge{
    width: auto;
    height: 14px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    color: #171725;
    background-color: #FFD200;
    border-radius: 4px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.objIdAndType_type{
    height:22px;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    text-align: right;
    justify-self: flex-end;
    color: #696974;
    margin-left: auto;
}
.objTitle{
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #171725;
    margin-bottom: 16px;
}
.objDiscription{
    font-family: 'Segoe UI';
    font-size: 14px;
    color: #696974;
    margin-bottom: 8px;
}
.objDiscription li{
    list-style-type: none;
    list-style-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M-0.000426292 4C-0.000426292 6.20914 1.79053 8 3.99979 8C6.20904 8 8 6.20914 8 4C8 1.79086 6.20904 0 3.99979 0C1.79053 0 -0.000426292 1.79086 -0.000426292 4Z' fill='%23E2E2EA'/%3e%3c/svg%3e ");
}
.objMore{
    font-family: 'Segoe UI';
    font-size: 14px;
    text-decoration-line: underline;
    color: #139DD9;
    margin-bottom: 16px;
    cursor: pointer;
}
.objOwnerName{
    display: flex;
    margin-bottom: 16px;
}
.objOwner, .objRenter{
    color: #696974;
    display: flex;
    align-items: center;
    margin: 2px 0;
}
.objOwner span, .objRenter span{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    padding-right: 4px;
}
.objOwner svg, .objRenter svg{
    margin-left: 6px;
    cursor: pointer;
}

/** --RIGHT BLOCK-- **/

.rightBlock{
    width: 240px;
    padding-left: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
}

/**STATUS**/
.rightBlock_Status{
    width: 100%;
    border-bottom: 1px dashed #E2E2EA;
    padding-bottom: 14px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    text-align: right;
}
.colorLightGrey{
    color: #92929D;
}
.colorDark{
    color: #171725;
}
.colorLightBrown{
    color: #A68800;
}
.colorGreen{
    color: #00B191;
}
.colorMeedllGrey{
    color: #696974;
}

/**DATE**/
.rightBlock_Date{
    width: 100%;
    border-bottom: 1px dashed #E2E2EA;
    padding-top: 6px;
    padding-bottom: 16px;
}
.rightBlock_Start{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0 0;
}
.rightBlock_date_text{
    width:100%;
    font-size: 14px;
    line-height: 19px;
    color: #696974;
}

.rightBlock_date_time{
    width:100%;
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    color: #696974;
}

.rightBlock_date_timeSpan{
    font-weight: normal;
    margin-left: 5px;
}
.rightBlock_date_txt{
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    color: #696974;
}
.rightBlock_CostAndButtons{
    width: 100%;
    padding-top: 8px;
    margin-bottom: 15px;
}
.costText{
    text-align: right;
    color: #696974;
    align-self: stretch;
    margin-top: 8px;
    margin-bottom: 4px;
}
.rentPeriod{
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    color: #171725;
    margin-bottom: 16px;
}
.costSumm{
    font-weight: 600;
    font-size: 16px;
    text-align: right;
    color: #171725;
}
.buttonsLine{
    position: relative;
    margin-top: auto;
    display: flex;
}
.buttonView{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: 100%;
    height: 40px;
    font-weight: 600;
    color: #696974;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
}
.buttonView:hover{
    border: 1px solid #139DD9;
    transition: .2s;
}
.noDisplay{
    display: none;
}
.infoBlock a{
    cursor: pointer;
    font-style: italic;
    color: #696974;
}
.link{
    width: 100%;
    color: inherit;
    text-decoration: inherit;
}