.topSection{
    width: 100%;
    height: auto;
    margin: 0 auto;
    border-bottom: 1px solid #E2E2EA;

}
.titleBlock{
    width: 100%;
    height: 100px;
    background-color: #FFF;
    position: relative;
    padding: 30px 20px;
    border-radius: 0 0 8px 8px;
    box-sizing: border-box;
}
.topSection h1{
    font-size: 32px;
    color: #171725;
    margin: 10px 0 24px 10px;
}
.createObjectBtn{
    position: absolute;
    right: 30px;
    top: 30px;
    padding: 10px 16px;
    width: 138px;
    height: 40px;
    background: #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    text-align: center;
    color: #FAFAFB;
}
.topMenu{
    height: 45px;
    display:flex;
    /* justify-content: space-between; */
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    padding-top: 30px;
}
.topMenuGroup{
    display: flex;
}
.topMenuItem{
    position: relative;
    padding:0px;
    font-weight: 600;
    color: #696974;
    margin: 0px 25px;
    cursor: pointer;
}
.activeMenuItem{
    position: relative;
    padding:0px;
    margin: 0px 25px;
    font-weight: 600;
    cursor: pointer;
    color:#139DD9;
}
.activeMenuItem::before {
    position: absolute;
    height: 5px;
    width: 100%;
    top: 40px;
    left: 0px;
    background-color: #139DD9;
    content: ' ';
    border-radius: 10px 10px 0 0;
}
.topMenu select{
    position: absolute;
    top: 65px;
    right: 0px;
    width: 285px;
    height: 40px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    padding-left: 12px;
    color: #171725;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.59 6.37'%3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:%2392929d;fill-rule:evenodd;%7d%3c/style%3e%3c/defs%3e%3cg id='Слой_2' data-name='Слой 2'%3e%3cg id='Слой_1-2' data-name='Слой 1'%3e%3cpath class='cls-1' d='M9.09,0a.5.5,0,0,1,.39.81L5.18,6.18a.5.5,0,0,1-.78,0L.11.81A.5.5,0,0,1,.5,0Z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
    cursor: pointer;
}
/* select::-ms-expand { display: none; }  */
.topMenu select:focus{
    outline:none;
}
a {
    text-decoration: none;
    color: none;
}
a:visited{
    color: inherit;
}