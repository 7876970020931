@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.paginationWrap {
    width: 100%;
    height: 36px;
    position: relative;
    margin: 0 auto 20px;
    box-sizing: border-box;
}

.showMoreResBtn {
    height: 40px;
    width: 1120px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.showMoreText {
    font-family: sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    text-align: center;
    color: #696974;
}

.showMoreResBtn:hover .showMoreText{
    color: #139DD9;
    transition: 0.2s;
}
.showMoreResBtn:hover {
    border: 1px solid #139DD9;
    transition: 0.2s;
}

.showMoreResBtn::before {
    margin:2px 9px 0px 0px;
    content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M13.3278 5.37361L11.1924 7.5125C11.0686 7.63472 10.9139 7.69583 10.7437 7.69583C10.5735 7.69583 10.4187 7.63472 10.2949 7.5125L8.15955 5.37361C7.92744 5.12916 7.92744 4.74722 8.15955 4.50278C8.40713 4.27361 8.79397 4.27361 9.04155 4.50278L10.233 5.69444C10.0783 3.47917 8.23692 1.72222 5.99321 1.72222C3.64119 1.72222 1.73791 3.64722 1.73791 6C1.73791 8.35278 3.64119 10.2778 5.99321 10.2778C6.85975 10.2778 7.69533 10.0181 8.39165 9.52917C8.67018 9.33055 9.05702 9.40694 9.25819 9.68194C9.45934 9.95694 9.38198 10.3389 9.10345 10.5375C8.19049 11.1639 7.10733 11.5 5.99321 11.5C2.96034 11.5 0.5 9.04028 0.5 6C0.5 2.95972 2.96034 0.499999 5.99321 0.499999C8.8404 0.499999 11.1924 2.68472 11.4555 5.48056L12.4303 4.50278C12.6779 4.25833 13.0648 4.25833 13.3123 4.50278C13.5599 4.74722 13.5599 5.12916 13.3278 5.37361Z' fill='%23139DD9'/%3e%3c/svg%3e");
}

.pagination {
    display: flex;
    height: 32px;
    width: 100%;
    margin-top: 20px;
}

.pageDiscription {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #696974;
    padding-top: 5px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently
                                    not supported by any browser */
}

.pagePagesList {
    display: flex;
    /*width: 392px;*/
    display: flex;
    margin-left: auto;
    /* order: 2; */
}

.pageBackNav {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 0 5px; 
    cursor: pointer;

}
.pageFwdNav{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 0 5px;
    cursor: pointer;
}
.pagination ul {
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 5px 10px;
    padding: 0;
    text-align: center;
}

.font_body_reg {
    color: #92929D;
}

.font_active_page {
    font-weight: 600;
    color: #139DD9;
}

.pagination li {
    height: 32px;
    min-width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border: 1px solid #FAFAFB;
    box-sizing: border-box;
    border-radius: 8px;
    list-style-type: none;
    cursor: pointer;
    margin: 0 5px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently
                                    not supported by any browser */
}
li.dots{
    cursor: default;
}
.pageBackNav:hover .svgIcon,
.pageFwdNav:hover .svgIcon,
.counterDn:hover .svgIcon,
.counterUp:hover .svgIcon {
    fill: #139DD9;
    transition: 0.2s;
}

.pagination li:hover:not(.dots),
.pageBackNav:hover,
.pageFwdNav:hover {
    border: 1px solid #139DD9;
    box-sizing: border-box;
    border-radius: 8px;
    transition: 0.2s;
}

.pageNavCounter {
    height: 32px;
    width: 61px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    order: 3;
    margin-left: 30px;
    align-items: right;
    justify-content: flex-end;
    flex-wrap: wrap;
}
.arrowsCountainer{
    display: flex;
    flex-flow: column nowrap;
}
.counterUp {
    width: 19px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 8px 0px 0px;
    cursor: pointer;
}
.counterDn {
    width: 19px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 0px 8px 0px;
    cursor: pointer;
}
.pageNumber {
    width: 39px;
    height: 32px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #92929D;
    border-right: 1px solid #E2E2EA;
    padding-top: 5px;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently
                                    not supported by any browser */
}