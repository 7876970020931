.wrap{
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
}
.container{
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}
.syncButton{
    position: absolute;
    right: 100px;
}