.itemWrap{
    display: flex;
    flex-flow: row;
    width: 100%;
    border: 1px solid var(--borderGray);
    padding: 12px;
    border-radius: 8px;
    transition: .3s;
}
.itemWrap:hover{
    border: 1px solid var(--brightBlue);
    transition: .3s;
}
.left{
    display: flex;
    flex-flow: column;
    flex: 3;
    padding-right: 8px;
}
.right{
    display: flex;
    flex-flow: column;
    flex: 2;
    border-left: 1px dashed var(--borderGray);
    padding: 0 8px 0 10px;
}
.third{
    display: flex;
    flex-flow: column;
    flex: .3;
    border-left: 1px dashed var(--borderGray);
    padding: 0 8px 0 10px;
}
.title{
    display: flex;
    flex-flow: column;
    flex: 4;
}
.titleName{
    font-size: 18px;
    font-weight: 600;
    margin-top: 15px;
    flex-grow: 1;
}
.number{
    display: flex;
    color: var(--darkGray);
}
.value{
    display: flex;
    color: var(--darkGray);
    margin-top: 10px;
}
.name{
    display: flex;
    color: var(--black);
}
.subTitle{
    color: var(--darkGray);
}
.status{
    flex: 2;
}
.statusName{
    text-transform: uppercase;
    font-weight: 600;
}
.termin{
    flex: 4;
    margin-top: 10px;
}
.dates{
    flex: 4;
    margin-top: 10px;
}
.draft{
    color: var(--lightGray);
    font-weight: 600;
    margin-bottom: 15px;
}
.buttonWrap {
    display: flex;
    gap: 8px;
    flex-flow: column;
    /* align-items: flex-start; */
}
.buttonEdit {
    display: inline-block;
    box-sizing: border-box;
    padding: 11px;
    border: 1px solid #E2E2EA;
    background-color: transparent;
    border-radius: 4px;
    height: 42px;
    transition: all 0.2s;
    cursor: pointer;
}
.buttonEdit:hover {
    background-color: #E2E2EA;
}
.buttonDelete {
    padding: 10px;
    border: 1px solid #FFA047;
    background-color: transparent;
    border-radius: 4px;
    height: 42px;
    transition: all 0.2s;
    cursor: pointer;
}
.buttonDelete:hover {
    background-color: #FFA047;
}
.buttonDelete:hover svg path {
    fill: #FFFFFF;
}
.buttonView {
    padding: 10px;
    border: 1px solid #E2E2EA;
    background-color: transparent;
    border-radius: 4px;
    height: 42px;
    transition: all 0.2s;
    cursor: pointer;
}
.buttonView:hover {
    background-color: #139DD9;
}
.buttonView:hover svg path {
    fill: #FFFFFF;
}