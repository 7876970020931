.wrapPage  {
    width: 1176px;
    height: auto;
    display: flex;
    flex-flow: row wrap;
    margin: 30px auto 0;
    padding: 0px 30px 10px 10px;
    box-sizing: border-box;
}
.objTitle{
    font-size: 24px;
    font-weight: 600;
}
.objContent {
    width: 780px;
    height: auto;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.objContent .actItem:last-child{
    margin-bottom:0;
}
.objNav {
    position: relative;
    height: 50px;
    margin-top: 32px;
    display:flex;
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    border-bottom: 1px solid #E2E2EA;
}
.objNavItem {
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    cursor: pointer;
    box-sizing: border-box;
    padding-bottom: 25px;
    position: relative;
    height: 40px;
    padding:0px;
    margin: 0px 25px;
}
.activeNavItem {
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    cursor: pointer;
    box-sizing: border-box;
    padding-bottom: 25px;
    position: relative;
    height: 40px;
    padding:0px;
    margin: 0px 25px;
    color: #139DD9;
}
.activeNavItem::before{
    position: absolute;
    height: 5px;
    width: 100%;
    top: 45px;
    left: 0px;
    background-color: #139DD9;
    content: ' ';
    border-radius: 10px 10px 0 0;
}
/* leftBlock */ 
.mainBlock {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

