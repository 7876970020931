.mapWrap{
    width: 100%;
    display: flex;
    flex-flow: column;
}
.mapTitle{
    font: 600 14px/14px 'robotolight',Arial,sans-serif;
}
.map{
    width: 100%;
    height: 400px;
    border-radius: 4px;
    border: 1px solid #00aff0;
    padding: 2px;
}