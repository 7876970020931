.wrapper {
    width: 100%;
    padding: 24px;
    background-color: #FFFFFF;
    margin-bottom: 25px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    box-sizing: border-box;
}
.wrapper > div:last-child {
    margin-bottom: 0;
}
.buttonUpdate {
    /* position: absolute;
    right: 24px;
    top: 50px; */
    height: 41px;
    margin-left: 14px;
    position: relative;
    top: -12px;
    padding: 10px 16px;
    background-color: #FFFFFF;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    color: #696974;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonUpdate:hover {
    background-color: #E2E2EA;
}
.title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
}
.subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 15px;
}
.fullWidth {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 0;
}
.thirdWidth {
    width: 32%;
    margin: 0;
}
.addPeriodButton {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #139DD9 !important;
    border: 1px solid #139DD9;
    border-radius: 4px;
    background-color: transparent;
    padding: 10px 16px;
    margin-bottom: 20px;
    cursor: pointer;
    transition: all 0.2s;
}
.addPeriodButton:hover {
    background-color: #139DD9;
    color: #FFFFFF !important;
}
.scheduleBy{
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    margin: 0 0 20px;
}
.scheduleHours{
    display: flex;
    width: 30%;
    flex-flow: column nowrap;
}
.scheduleDays{
    display: flex;
    width: 30%;
    flex-flow: column nowrap;
}