.buttonPost {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 10px 16px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    background-color: #139DD9;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonPost:hover {
    background-color: transparent;
    color: #139DD9;
}
.buttonPostInAct{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 10px 16px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    background-color: #E2E2EA;
    cursor: default;
    transition: all 0.2s;
}