.addDocumentButton {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #139DD9;
    padding: 10px 16px;
    background-color: #FFFFFF;
    border: 1px solid #139DD9;
    border-radius: 4px;
    transition: all 0.2s;
    cursor: pointer;
}
.addDocumentButton:hover {
    background-color: #139DD9;
    color: #FFFFFF;
}
.docListContainer{
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 20px 0;
    gap: 10px;
}