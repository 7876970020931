.infoBlock {
    position: relative;
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    /* margin: 20px 0; */
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.infoBlockMin{
    position: relative;
    width: 100%;
    height: 68px;
    border: 1px solid #E2E2EA;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    /* margin: 20px 0; */
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
}
.noborder{
    border: none;
}
.infoBlock h3 {
    margin: 0 0 16px 0;
}
.infoBlock h3:last-child {
    margin-bottom: 0;
}
.infoBlock > div:last-child{
    margin-bottom:0;
}
.infoBlockTitle{
    flex: 6;
    height: auto;
    margin-bottom: 16px;
    margin-right: auto;
}
.infoBlockTitle h3{
    margin: 0;
}
.generalInfo{
    border: none;
}
.titleBlock{
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
}
.wrapBtn{
    flex: 1;
    margin-left: 14px;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    text-decoration-line: underline;
    color: #139DD9;
    cursor: pointer;
}
.infoBlock p.desc{
    font-family: 'Segoe UI';
    font-size: 14px;
    margin: 2px 0 0;
}
