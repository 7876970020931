.wrapper {
    padding: 24px;
    background-color: #FFFFFF;
    margin-bottom: 25px;
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
}
.blockFlex {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;
}
.buttonUpdate {
    /* position: absolute; */
    right: 24px;
    top: 14px;
    padding: 10px 16px;
    margin-bottom: 16px;
    margin-left: auto;
    background-color: #FFFFFF;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    color: #696974;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonUpdate:hover {
    background-color: #E2E2EA;
}
.titleWrap {
    width: 100%;
    display: flex;
    align-items: center;
}
.inputWrap {
    width: 100%;
    margin-right: 16px;
}
.titleBtn{
    display: flex;
    flex-flow: row;
    width: 100%;
}