.wrapper {
    padding: 24px;
    background-color: #FFFFFF;
    margin-bottom: 25px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
}
.wrapper > div:last-child {
    margin-bottom: 0;
}
.title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 16px;
}
.titleError {
    color: #FFA047;
}
.organizationsWrap {
    width: 100%;
    margin-bottom: 25px;
}
.organization {
    background: #FFFFFF;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    padding: 24px;
    position: relative;
}
.organizationTitle {
    padding-right: 100px;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #171725;
    margin-bottom: 32px;
}
.organizationButtonWrap {
    position: absolute;
    top: 24px;
    right: 24px;
    display: flex;
    align-items: flex-start;
}
.organizationInfoContent{
    display: flex;
    flex-flow: row wrap;
}
.buttonEdit {
    padding: 11px;
    margin-right: 8px;
    border: 1px solid #E2E2EA;
    background-color: transparent;
    border-radius: 4px;
    height: 42px;
    transition: all 0.2s;
    cursor: pointer;
}
.buttonEdit:hover {
    background-color: #E2E2EA;
}
.buttonDelete {
    padding: 10px;
    border: 1px solid #FFA047;
    background-color: transparent;
    border-radius: 4px;
    height: 42px;
    transition: all 0.2s;
    cursor: pointer;
}
.buttonDelete:hover {
    background-color: #FFA047;
}
.buttonDelete:hover svg path {
    fill: #FFFFFF;
}
.addOrganization {
    text-align: left;
    font-size: 14px;
    line-height: 19px;
    width: 100%;
    height: 40px;
    border: 1px solid #CED6E0;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: inherit;
    outline: none;
    color: #92929D;
    padding: 10px 20px 10px 10px;
    position: relative;
    cursor: pointer;
    transition: .5s;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.addOrganization:after {
    position: absolute;
    border: 6px solid transparent;
    border-top: 6px solid #92929D;
    top: 18px;
    right: 8px;
    font-size: 16px;
    content: ' ';
    color: #92929D;
}
.addOrganization:hover, .addOrganization:focus {
    border: 1px solid #00AFF0;
    transition: .2s;
}
.addOrganizationError {
    border-color: #FFA047 !important;
}

.buttonUpdate {
    margin-left: auto;
    margin-bottom: 16px;
    padding: 10px 16px;
    background-color: #FFFFFF;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    color: #696974;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonUpdate:hover {
    background-color: #E2E2EA;
}
.buttonSave {
    margin-left: 16px;
    margin-bottom: 16px;
    padding: 10px 16px;
    background-color: #FFFFFF;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    color: #696974;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonSave:hover {   
    background-color: #E2E2EA;
}
.updateWrapper {
    width: 100%;
}
.infoWrapper {
    width: 100%;
}
.ownershipTypeWrap {
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 10px;
}
.errorDescription {
    font-size: 12px;
    line-height: 16px;
    color: #FFA047;
    margin: 3px 0 0px 10px;
}