.search{
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 24px;
}
.searchWrap_title{
    margin-bottom: 32px;
}
.searchTitle{
    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43px;
    color: #000000;
}
.inputKeyword_line{
    width: 100%;
    line-height: 36px;
    border: 1px solid #CED6E0;
    box-sizing: border-box;
    border-radius: 4px;
    padding-left: 36px;
}
.inputKeyword svg{
    position: absolute;
    top: 10px;
    left: 13.5px;
}
.searchFindBlock{
    display: flex;
}
.inputKeyword{
    width: 1002px;
    margin-right: 12px;
    position: relative;
    margin-bottom: 28px;
}
.findStart{
    width: 106px;
    height: 40px;
    background: #171725;
    border-radius: 4px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    margin-left: 8px;
    cursor: pointer;
}
.findStart svg{
    margin-left: 16px;
    margin-right: 8px;
}
.findStart a{
    color: #FFFFFF;
}
.searchFilterBlock{
    display: flex;
}
.searchFilterBlock_icon{
    margin-right: 15.22px;
    margin-top: 6px;
}
.searchFilterBlock_status{
    /*width: 57px;*/
    /*height: 32px;*/
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #696974;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    cursor: pointer;
    margin-right: 8px;
}
.searchFilterBlock_status:hover{
    border: 1px solid #139DD9;
    transition: .2s;
}
.searchFilterBlock_status_dop{
    margin-left: 8px;
    padding-left: 16px;
    border-left: 1px dashed #E2E2EA;
}
.searchFilterBlock_status_select{
    /*width: 57px;*/
    height: 32px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;
    appearance: none;
    padding-left: 8px;
}
.badgesWrapLine{
    margin-top:12px;
    display: flex;
    border-top: 1px dashed #E2E2EA;
    padding: 16px 12px 0 12px;
    flex-wrap: wrap;
}
.badgeElem{
    width: auto;
    padding: 4px 12px;
    background: #FAFAFB;
    border-radius: 100px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #696974;
    cursor: pointer;
    display: flex;
    align-items: center;
    display: flex;
    margin-right: 2px;
    margin-bottom: 2px;
}
.badgeElem_title{
    width: auto;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
}
.badgeElem svg{
    margin-left: 11.33px;
}
.badgeElem svg:hover{
    fill:aqua;
}
svg.svgIcon:hover{
    transition: .2s;
    fill: #139DD9;
}
.countStatus{
    border-left: 1px dashed #E2E2EA;
    padding-left: 6.5px;
    padding-right: 6.5px;
    margin-left: 6.5px;
}

/* PopUp */
.wrapMain{
    display: flex;
    justify-content: center;
    align-items:flex-start;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.2);
    backdrop-filter: blur(1px) grayscale(60%);
    z-index: 9998;
}
.wrap{
    display: flex;
    flex-direction: column;
    width: 480px;
    /*height: calc(auto + 25px);*/
    max-height: 90vh;
    /* min-height: 35vh; */
    background-color: #ffffff;
    z-index: 9999;
    margin: 2vh 0;
    padding: 14px 0;
    border-radius: 4px 4px 4px 4px;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
}
.none{
    display: none;
}
.filterCount{
    padding: 0 16px;
    display: flex;
    color: #57606F;
}
.filterTitle{
    padding: 0 16px;
    margin-top: 8px;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    /* min-height: 250px; */
    height: calc(100% + 20px);
    margin: 10px 0;
    padding: 16px ;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}
.bottomPart{
    height: 56px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    border-top: 1px solid #E2E2EA;
    padding: 16px 16px 0;
    align-items: flex-end;
    justify-content: flex-end;
    box-sizing: border-box;
}

.greyBtn{
    width: auto;
    height: 30px;
    padding: 0 12px;
    cursor:pointer;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    display: flex;
    border: 1px solid #dfdfdf;
    color: #696974;
}
.greyBtn:hover {
    transition: .2s;
    background-color: #dfdfdf;
    /* color: #ffffff; */
}
/* input[type='number']{
    display: flex;
    height: 28px;
    width: 120px;
    border-radius: 4px;
    text-align: center;
    outline: none;
    border: 1px solid #dfdfdf;
} */
.btw{
    margin: 0 10px 0 30px;
}
/* Скрыть стрелочки*/
/*TODO: стрелочки потом починить */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */
/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */
.bages{
    width: 100%;
    height: auto;
    display: flex;
}

.filterReset {
    margin-right: auto;
    color: #FFA047;
    cursor: pointer;
    text-decoration: underline;
}