.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
    backdrop-filter:  blur(1px) grayscale(60%);
}
.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popupBody {
    max-width: 518px;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
    border-radius: 10px;
    padding: 50px 25px 25px;
    position: relative;
}
.closeButton {
    width: 18px;
    height: 18px;
    background: none;
    border: none;
    position: absolute;
    top: 25px;
    right: 25px;
    transform:rotate(45deg);
    -ms-transform:rotate(45deg);
    -webkit-transform:rotate(45deg);
    cursor: pointer;
    transition: all 0.2s;
 }

.closeButton:before, .closeButton:after {
    content: "";
    position: absolute;
    z-index: -1;
    background-color: #92929D;
 }
 .closeButton:hover:before, .closeButton:hover:after {
     background-color: #424242;
 }

.closeButton:before {
    width: 2px;
    margin-top: -50%;
    margin-left: -1px;
    height: 100%;
 }

.closeButton:after {
    height: 2px;
    margin-left: -50%;
    margin-top: -1px;
    width: 100%;
 }
.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #171725;
    margin-bottom: 30px;
}

.attachWrapper{
    position: relative;
  }
  
.attachHiddenInput {
    display: none;
}
.attachButton {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #139DD9;
    padding: 10px 16px;
    margin-bottom: 15px;
    background-color: #FFFFFF;
    border: 1px solid #139DD9;
    border-radius: 4px;
    transition: all 0.2s;
    cursor: pointer;
}
.attachButton:hover {
    background-color: #139DD9;
    color: #FFFFFF;
}
.attachBlock {
    background: #FFFFFF;
    border: 1px solid #00AFF0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 18px 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.attachFileNameWrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 10px;
}
.attachFileName {

}
.attachDelete {
    
}

.buttonWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 32px;
}
.buttonCancel {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #139DD9;
    padding: 10px 16px;
    background-color: #FFFFFF;
    border: 1px solid #139DD9;
    border-radius: 4px;
    transition: all 0.2s;
    cursor: pointer;
}
.buttonCancel:hover {
    background-color: #139DD9;
    color: #FFFFFF;
}
.buttonUpload {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    padding: 10px 16px;
    background-color: #139DD9;
    border: 1px solid #139DD9;
    border-radius: 4px;
    transition: all 0.2s;
    cursor: pointer;
}
.buttonUpload:hover {
    background-color: #FFFFFF;
    color: #139DD9;
}

.fileError {
    margin-left: 15px;
    color: #FFA047;
}