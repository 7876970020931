.h1Title {
    font-family: "Roboto";
    font-weight: 900;
    font-size: 100px;
    line-height: 120px;
    width: 100%;
    display: flex;
    color: #171725;
    justify-content: center;
    align-items: center;
    align-self: auto;
    margin: 24px auto;
}

.image {
    height: 200px;
}

.wrap {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    overflow: auto;
}

.wrap .text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #44444F;
    margin: 8px 0px;
}

.wrap .hint {
    position: static;
    width: 100%;
    height: 16px;
    left: 0px;
    top: 488px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    color: #696974;
    margin: 32px 0px;
}

.wrap .support {
    position: static;
    height: 16px;
    left: 0px;
    right: 0px;
    top: 621px;

    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    color: #92929D;
    margin: 40px 0px;
}

@media screen and (max-width: 640px) {
    h1 {
        font-size: 60px;
        line-height: 70px;
        margin: 15px auto;
        text-align: center;
    }
    .wrap .text {
        margin: 8px 5px;
    }
}