.sortWrapper{
    display: flex;
    width: 550px;
    /* flex: 1; */
    gap: 25px;
    justify-content: space-between;
    padding-top: 25px;
}
.keywordWrap{
    display: flex;
    flex: 5;
    gap: 10px;
}
.orderWrap{
    display: flex;
    flex: 3;
    width: 200px;
}