.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999;
    backdrop-filter: blur(1px) grayscale(60%);
}
.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
.cont{
    display: flex;
    flex-flow: column;
    max-height: 80vh;
    /* max-width: 518px; */
    background-color: #FFFFFF;
    box-shadow: 0px 5px 15px rgba,68, 68, 79, 0.1;
    border-radius: 10px;
    padding: 25px 25px 25px;
    position: relative;
}
.imageContainer{
    display: flex;
}
.image{
    /* object-fit: contain; */
    max-height: calc(80vh - 50px);
}
.text{
    display: flex;
    width: 100%;
    font-size: 18px;
    justify-content: center;
}
.btnBlock{
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
}
.btn{
    border-radius: 4px;
}