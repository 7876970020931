.wrap{
    width: 100%;
    padding: 5px 0;
    background: #fff;
    font: 400 10.5px/11px 'robotobold',Arial,sans-serif;
    color: #000;
    text-transform: uppercase;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 200;
    box-shadow: 0 3px 4px #CCC;
}
.wrapB{
    width: 100%;
    height: 110px;
}

/*@media (min-width: 320px){
    .content{
        width: 300px;
        margin: auto;
        display: flex;
        justify-content: center;
    }
    .mobMenuIcon{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 730px){
    .content{
        width: 710px;
        margin: auto;
        display: flex;
        justify-content: center;
    }
    .mobMenuIcon{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 960px){
    .content{
        width: 940px;
        margin: auto;
        display: flex;
        justify-content: center;
    }
    .mobMenuIcon{
        display: none;
    }
}

@media (min-width: 1280px){*/
    .content{
        /*width: 1260px;*/
        width: calc(1200px - 84px);
        margin: auto;
        display: flex;
        justify-content: center;
        padding-left: 42px;
        padding-right: 42px;
    }
    .mobMenuIcon{
        display: none;
    }
/*}

@media (min-width: 1440px){
    .content{
        width: 1420px;
        margin: auto;
        display: flex;
        justify-content: center;
    }
    .mobMenuIcon{
        display: none;
    }
}

@media (min-width: 1600px){
    .content{
        width: 1580px;
        margin: auto;
        display: flex;
        justify-content: center;
    }
    .mobMenuIcon{
        display: none;
    }
}

@media (min-width: 1920px){
    .content{
        width: 1900px;
        margin: auto;
        display: flex;
        justify-content: center;
    }
    .mobMenuIcon{
        display: none;
    }
}*/