.wrapper {
    background: #FFFFFF;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    padding: 24px;
    margin-bottom: 24px;
    position: relative;
}
.title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #171725;
    padding-right: 100px;
    margin-bottom: 32px;
}
.buttonWrap {
    position: absolute;
    top: 24px;
    right: 24px;
    display: flex;
    align-items: flex-start;
}
.buttonEdit {
    display: inline-block;
    box-sizing: border-box;
    padding: 11px;
    margin-right: 8px;
    border: 1px solid #E2E2EA;
    background-color: transparent;
    border-radius: 4px;
    height: 42px;
    transition: all 0.2s;
    cursor: pointer;
}
.buttonEdit:hover {
    background-color: #E2E2EA;
}
.buttonDelete {
    padding: 10px;
    border: 1px solid #FFA047;
    background-color: transparent;
    border-radius: 4px;
    height: 42px;
    transition: all 0.2s;
    cursor: pointer;
}
.buttonDelete:hover {
    background-color: #FFA047;
}
.buttonDelete:hover svg path {
    fill: #FFFFFF;
}
.halfBlockWrap {
    display: flex;
}
.additionalClassifications {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.additionalClassifications li {
    margin-bottom: 5px;
    list-style-type: none;
}
.specificFields {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}