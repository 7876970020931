.setupFilterWrap {
    width: 1120px;
    height: 20px;
}

.setupFilterWrap div {
    text-decoration: underline;
    font-family: sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-right: 40px;
    display: inline;
}

.filterReset {
    color: #FFA047;
    cursor: pointer;
}

.filterActive {
    color:#57606F;
    cursor: pointer;
}

.filterUnActive {
    color:#E2E2EA;
    cursor: not-allowed;
}