@font-face {
  font-family: 'Segoe UI';
  src: url('../src/font/SegoeUI.eot');
  src: url('../src/font/SegoeUI.eot?#iefix') format('embedded-opentype'),
  url('../src/font/SegoeUI.woff') format('woff'),
  url('../src/font/SegoeUI.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI","sans-serif", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
 
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAFB;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: #139DD9;
}

.MuiDialog-root{
  z-index: 9999 !important;
}
 /* Scroll Bar */
*::-webkit-scrollbar {
    width: 5px;
}
*::-webkit-scrollbar-track {
    background: #f1f1f1;
}
*::-webkit-scrollbar-thumb {
    background: #92929D;
}
*::-webkit-scrollbar-thumb:hover {
    background: #696974;
}

:root {
  --attentionText: #A68800;
  --yellow: #FFEF75;
  --attantion:#FFD200;
  --red:#FFA047;
  --green:#00B191;
  --backgroundGray:#FAFAFB;
  --borderGray:#E2E2EA;
  --disabledGrey:#BFBFC6;
  --lightGray:#92929D;
  --darkGray: #696974;
  --black:#171725;
  --darkBlue:#06648D;
  --brightBlue:#139DD9;
  --blue: #00AFF0;
}