.wrapper {
    max-width: 1176px;
    width: 100%;
    margin: 0 auto 25px;
    padding-top: 16px;
    user-select: none;
}
.titleWrapper {
    display: flex;
    justify-content: space-between;
}
.title {
    font-weight: 600;
    font-size: 32px;
    line-height: 43px;
    color: #171725;
}
.buttonCreate {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #FAFAFB !important;
    background-color: #139DD9;
    border: 1px solid #139DD9;
    border-radius: 4px;
    padding: 10px 16px;
    text-decoration: none;
    transition: all 0.2s;
    cursor: pointer;
}
.buttonCreate:hover {
    background-color: #FAFAFB;
    color: #139DD9 !important;
}
.tableContent{
    margin:12px auto 18px;
    width: 1176px;
    height: auto;
    top: 12px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    padding:4px;
    box-sizing: border-box;
}
.myObjectsTable{
    width:100%;
    font-size: 14px;
}
.tabHead{
    position:relative;
    width: 100%;
    display: flex;
    box-sizing: border-box;
    padding:12px 6px;
}
/* .myRequestTable .tabHead{
    grid-template-columns: 313px 225px 132px 238px 52px 104px 86px;
}

.myActionsTable .tabHead{
    grid-template-columns: 471px 225px 132px 132px 104px 86px;
} */

.thName,
.thStat,
.thStatus,
.thRent,
.thAction,
.thDeclaration,
.thActions{
    display: flex;
    color: #696974;
    font-weight: 600;
    font-size: 14px;
    padding: 0 6px;
}
.thName{
    flex: 5;
}
.thStat{
    flex: 3;
}
.thStatus{
    flex: 3;
}
.thRent{
    flex: 2;
}
.thAction,
.thDeclaration,
.thActions{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    font-weight: 600;
    flex: 1;
    text-align:right;
}
.tableEmpty {
    text-align: center;
    padding: 24px;
    background-color: #FFFFFF;
}