.blockWrap{
    position: relative;
    width: 780px;
    height: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 15px;
    padding: 15px 0;
}
.infoBlock {
    width: 100%;
    height: auto;
    /* border: 1px solid #E2E2EA; */
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    /* margin: 20px 0 0 0; */
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.generalInfo{
    border: none;
}
.infoBlockTitle{
    width: 100%;
    height: auto;
    margin: 0 0 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.infoBlockContent, .infoBlockOrgContent{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}
.infoBlockOrgContent{
    gap: 20px;
}
.organizationWrap {
    width: 100%;
    /* height: auto; */
    /* border: 1px solid #E2E2EA; */
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    /* margin: 20px 0 0 0; */
    /* padding: 24px; */
    display: flex;
    flex-flow: row wrap;
}
.infoBlock a{
    color: #139DD9;
}
.twoBlock{
    display: flex;
    gap: 15px;
    width: 100%;
}
