.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vH;
}
.main {
    flex-grow: 1;
    margin-top: 111px;
}
.container {
    width: 100%;
    max-width: 1176px;
    margin:0 auto;
    padding: 0 12px;
}