.wrapper {
    border-radius: 4px;
    padding: 0 24px 24px;
    position: relative;
    width: 780px;
    height: auto;
}
.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #171725;
    margin-bottom: 32px;
}
.attention {
    border: 1px solid #00AFF0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 24px;
    display: flex;
    gap: 26px;
    justify-content: space-between;
    margin-bottom: 8px;
}
.attentionIcon {
    min-width: 32px;
    height: 32px;
    background-color: #FFD200;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.attentionContent {
    width: 100%;
}
.attentionTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
}
.buttonWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    margin: 20px 0 0;
}

.buttonCancel {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #696974 !important;
    padding: 10px 16px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonCancel:hover {
    background-color: #E2E2EA;
}
.buttonSave {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 10px 16px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    background-color: #139DD9;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonSave:hover {
    background-color: transparent;
    color: #139DD9;
}
.buttonAdd {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #139DD9;
    padding: 10px 16px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonAdd:hover {
    background-color: #139DD9;
    color: #FFFFFF;
}
.buttonSend {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 10px 16px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    background-color: #139DD9;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonSend:hover {
    background-color: transparent;
    color: #139DD9;
}

.docListContainer{
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 20px 0;
    gap: 10px;
}