a {
    text-decoration: none;
}
.actItem{
    width: 780px;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
    padding: 32px;
    margin: 16px 0;
    display: flex;
}
.centralBlock{
    width: 421px;
    border-right: 1px dashed #E2E2EA;
    padding-right: 24px;
}
.bottomBlock{
    display: flex;
    flex-direction:column;
}
.topBlock{
    width: 100%;
    border-bottom: 1px dashed #E2E2EA;
    margin-bottom: 8px;
    padding: 0 0 16px 0;
}

.actIdAndType{
    width: 100%;
    display: flex;
    flex-direction: column;

}
.actIdAndType_id{
    font-size: 14px;
    line-height: 19px;
    color: #696974;
    margin: 0 28px 0 0;
}
.yourActBadge {
    width: auto;
    height: 14px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    color: #171725;
    background-color: #FFD200;
    border-radius: 4px;
    padding: 4px;
    margin: 0 0 0 20px;
}
.actIdAndType_type{
    font-size: 14px;
    line-height: 19px;
    display: flex;
    text-align: left;
    justify-self: flex-end;
    color: #696974;
    margin: 8px 0 0 0;
}
.actTitle{
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #171725;
    margin: 16px 0;
    /* background-color: #FFD200; */
}

.docLinkd{
    color: #696974;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    font-weight: 600;
}
.docLinkd span{
    margin: 0 0 0 5px;
    margin-right: 5px;
    font-weight: 400;
}
.docLinkd svg{
    cursor: pointer;
}

.actDiscription{
    font-family: 'Segoe UI';
    font-size: 14px;
    /* height: 38px; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: #696974;
    margin-bottom: 8px;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.actDiscriptionExp{
    font-family: 'Segoe UI';
    font-size: 14px;
    height: auto;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: #696974;
    margin-bottom: 8px;
    -webkit-line-clamp: none;
    overflow: hidden;
}
.actMore{
    font-family: 'Segoe UI';
    font-size: 14px;
    text-decoration-line: underline;
    color: #139DD9;
    margin-bottom: 16px;
    cursor: pointer;
}
.actUserInfo{
    display: flex;
    margin-bottom: 16px;
}
.actUserInfo{
    color: #696974;
    display: flex;
    flex-flow: column nowrap;
    align-items: left;
    margin: 8px 0;
}
.actUserInfo span{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    padding-right: 4px;
}
.actUserName{
    display: flex;
    align-items: center;
}
.actUserInfo svg{
    margin-left: 6px;
    cursor: pointer;
}

/** --RIGHT BLOCK-- **/

.rightBlock{
    position: relative;
    width: 270px;
    padding-left: 24px;
    box-sizing: border-box;
}

/**STATUS**/
.rightBlock_Status{
    width: 100%;
    border-bottom: 1px dashed #E2E2EA;
    padding-bottom: 16px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    text-align: right;
}

.colorLightGrey{
    color: #92929D;
}
.colorDark{
    color: #171725;
}
.colorLightBrown{
    color: #A68800;
}
.colorGreen{
    color: #00B191;
}
.colorMeedllGrey{
    color: #696974;
}

/**DATE**/
.rightBlock_Date{
    width: 100%;
    border-bottom: 1px dashed #E2E2EA;
    padding-top: 6px;
    padding-bottom: 16px;
}
.rightBlock_Start{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0 0;
}

.rightBlock_date_text{
    width:130px;
    font-size: 14px;
    line-height: 19px;
    color: #696974;
}

.rightBlock_date_time{
    width:115px;
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    color: #696974;
}

.rightBlock_date_timeSpan{
    font-weight: normal;
}
.rightBlock_date_txt{
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    color: #696974;
}

.rightBlock_CostAndButtons{
    width: 100%;
    padding-top: 8px;
    display: flex;
}
.rightBtnsBlock{
    width: calc(100% - 24px);
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
}
.detailsBtn{
    height: 40px;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 40px;
    padding: 10px 16px;
    cursor:pointer;
    border: 1px solid #E2E2EA;
    border-radius: 8px;
    box-sizing: border-box;
    color: #696974;
    font-weight: 600;
}
.detailsBtn:hover{
    border: 1px solid #139DD9;
    transition: .2s;
}
.publishBtn{
    height: 40px;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 40px;
    padding: 10px 15px;
    cursor:pointer;
    background: #139DD9;
    border-radius: 8px;
    box-sizing: border-box;
    color: #FAFAFB;
    font-weight: 600;
}
.moreBtn{
    width: 40px;
    height: 40px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='4' viewBox='0 0 18 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 2.00037C4 0.895598 3.10457 0 2 0C0.89543 0 0 0.895598 0 2.00037C0 3.10515 0.89543 4.00075 2 4.00075C3.10457 4.00075 4 3.10515 4 2.00037Z' fill='%2392929D'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11 2.00037C11 0.895598 10.1046 0 9 0C7.89543 0 7 0.895598 7 2.00037C7 3.10515 7.89543 4.00075 9 4.00075C10.1046 4.00075 11 3.10515 11 2.00037Z' fill='%2392929D'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M18 2.00037C18 0.895598 17.1046 0 16 0C14.8954 0 14 0.895598 14 2.00037C14 3.10515 14.8954 4.00075 16 4.00075C17.1046 4.00075 18 3.10515 18 2.00037Z' fill='%2392929D'/%3e%3c/svg%3e ");
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 0 0 8px;
    border: 1px solid #92929D;
    color: #696974;
    display: flex;
    align-content: center;
    justify-content: center;
    cursor:pointer;
    border-radius: 8px;
    box-sizing: border-box;
    flex-shrink: 0;
}

.costText{
    text-align: right;
    color: #696974;
    align-self: stretch;
    margin-top: 8px;
    margin-bottom: 4px;
}
.rentPeriod{
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    color: #171725;
    margin-bottom: 16px;
}
.costSumm{
    font-weight: 600;
    font-size: 16px;
    text-align: right;
    color: #171725;
}
.buttonsLine{
    position: relative;
    margin-top: 16px;
    display: flex;
}
.buttonView{
    position: absolute;
    bottom:0px;
    right:0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: 240px;
    height: 40px;
    font-weight: 600;
    color: #696974;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
}
.noShow{
    display: none;
}
.nothing{
    width: 100%;
    height: 300px;
    display: flex;
    font-size: 28px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
}
.link{
    width: 100%;
    color: inherit;
    text-decoration: inherit;
}
.buttonAddAction {
    display: inline-block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #139DD9 !important;
    border: 1px solid #139DD9;
    border-radius: 4px;
    background-color: transparent;
    padding: 10px 16px;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonAddAction:hover {
    background-color: #139DD9;
    color: #FFFFFF !important;
}
