@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

/* a {
    text-decoration: none;
}
* {
    font-family: 'Segoe UI';
    font-size: 14px;
} */

.objItem{
    width: 1176px;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;
    flex: none;
    order: 5;
    align-self: stretch;
    flex-grow: 0;
    padding: 32px;
    margin: 0 auto;
    margin-bottom: 16px;
    display: flex;
}
.centralBlock{
    width: 816px;
    border-right: 1px dashed #E2E2EA;
    padding-right: 24px;
    box-sizing: border-box;
}
.bottomBlock{
    display: flex;
    flex-direction: column;
    position: relative;
}
.topBlock{
    width: 100%;
    border-bottom: 1px dashed #E2E2EA;
    margin-bottom: 8px;
    padding-bottom: 10px;
}

.objIdAndType{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 14.5px;
}
.objType{
    color: #696974;
    margin-bottom: 16px;
}
.objType span{
    font-weight: 600;
}
.objIdAndType_id{
    font-size: 14px;
    line-height: 19px;
    color: #696974;
    margin: 0 28px 0 0;
}
.yourObjBadge{
    width: auto;
    height: 14px;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    color: #171725;
    background-color: #FFD200;
    border-radius: 4px;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.objIdAndType_type{
    height:22px;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    text-align: right;
    justify-self: flex-end;
    color: #696974;
    margin-left: auto;
}

.objTitle{
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #171725;
    margin-bottom: 16px;
    cursor: pointer;
}

.objDiscription{
    font-family: 'Segoe UI';
    font-size: 14px;
    height: 38px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: #696974;
    margin-bottom: 8px;
    -webkit-line-clamp: 2;
    overflow: hidden;
}
.objDiscriptionExt{
    font-family: 'Segoe UI';
    font-size: 14px;
    height: auto;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    color: #696974;
    margin-bottom: 8px;
    -webkit-line-clamp: none;
    overflow: hidden;
}


.objDiscription li{
    list-style-type: none;
    list-style-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M-0.000426292 4C-0.000426292 6.20914 1.79053 8 3.99979 8C6.20904 8 8 6.20914 8 4C8 1.79086 6.20904 0 3.99979 0C1.79053 0 -0.000426292 1.79086 -0.000426292 4Z' fill='%23E2E2EA'/%3e%3c/svg%3e ");
}

.objMore{
    font-family: 'Segoe UI';
    font-size: 14px;
    text-decoration-line: underline;
    color: #139DD9;
    margin-bottom: 16px;
    cursor: pointer;
}

.objOwnerName{
    display: flex;
    margin-bottom: 16px;
}

.objOwner, .objRenter{
    color: #696974;
    display: flex;
    align-items: center;
    margin: 8px 0;
}
.objOwner span, .objRenter span{
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    padding-right: 4px;
}
.objOwner svg, .objRenter svg{
    margin-left: 6px;
    cursor: pointer;
}

/** --RIGHT BLOCK-- **/

.rightBlock{
    width: 290px;
    padding-left: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

/**STATUS**/
.rightBlock_Status{
    width: 100%;
    border-bottom: 1px dashed #E2E2EA;
    padding-bottom: 16px;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    text-align: right;
}

.colorLightGrey{
    color: #92929D;
}
.colorDark{
    color: #171725;
}
.colorLightBrown{
    color: #A68800;
}
.colorGreen{
    color: #00B191;
}
.colorMeedllGrey{
    color: #696974;
}

/**DATE**/
.rightBlock_Date{
    width: 100%;
    border-bottom: 1px dashed #E2E2EA;
    padding-top: 6px;
    padding-bottom: 16px;
    position: relative;
}
.rightBlock_Start{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0 0;
}

.rightBlock_date_text{
    width:100%;
    font-size: 14px;
    line-height: 19px;
    color: #696974;
}

.rightBlock_date_time{
    width:100%;
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    color: #696974;
}

.rightBlock_date_timeSpan{
    font-weight: normal;
    margin-left: 5px;
}
.rightBlock_date_txt{
    font-weight: 600;
    font-size: 14px;
    text-align: right;
    color: #696974;
}

.rightBlock_CostAndButtons{
    width: 100%;
    padding-top: 8px;
    margin-top: auto;
    position: relative;
}

.buttonsLine{
    position: relative;
    margin-top: auto;
    display: flex;
}
.buttonView{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: 100%;
    height: 40px;
    font-weight: 600;
    color: #696974;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
}
.buttonView:hover{
    border: 1px solid #139DD9;
    transition: .2s;
}
.noDisplay{
    display: none;
}
/* Modal Info */
.infoModal{
    position: relative;
}
.svgInfo:hover .svgIcon{
    fill:#139DD9;
    transition: .2s;
}
.wrapperInfo {
    position:  fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 998;
    /* background:rgba(0, 0, 0, 0.05); */
}
.infoBlock{
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    width: 440px;
    height:auto;
    /* min-width: 440px; */
    box-sizing: border-box;
    bottom: 38px;
    right: -212px;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px;
    z-index: 999;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    cursor: default;
}
.infoBlock::after{
    position: absolute;
    content: ' ';
    bottom: -10px;
    left: 210px;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background-color: inherit;
    box-shadow: 5px 4px 4px 0px rgb(0 0 0 / 5%);
    z-index: 999;
}
.noDisplay{
    display:none;
}
.infoBlock p {
    color: #171725;
    padding: 2px 2px 0;
    margin: 0;
}
.bottomBtnBlock{
    width: 100%;
    height: auto;
    position: relative;
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.inputInfoBlockBtn {
    width: auto;
    height: 32px;
    padding: 10px 16px;
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #139DD9;
    color: #139DD9;
    cursor: pointer;
}
.infoBlock a{
    cursor: pointer;
    font-style: italic;
    color: #696974;
}
.link{
    width: 100%;
    color: inherit;
    text-decoration: inherit;
}