.wrapMain{
    display: flex;
    justify-content: center;
    align-items:flex-start;
    position: fixed;
    overflow: auto;
    padding: 20px 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 998;
    backdrop-filter: blur(1px) grayscale(60%);
}
.wrap{
    display: flex;
    position: relative;
    flex-direction: column;
    width: 780px;
    height: auto;
    background-color: #ffffff;
    z-index: 999;
    margin: auto;
    font-size: 14px;
    padding: 24px;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
    transition: .8s;
}
.close{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 24px;
    height: 24px;
    right:20px;
    top:20px;
    font-size: 18px;
    cursor:pointer;
    color:#92929D;
    transition: .2s;
}
.close:hover .closeSVG{
    fill:#139DD9;
    transition: .2s;
}
.close:hover{
    color:#139DD9;
    transition: .2s;
}
.popupTitle{
    font-size: 24px;
    text-align: left;
    margin-bottom: 15px;
}
.description{
    margin: 8px 0 4px;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: calc(100% + 20px);
    margin: 10px 0;
    padding: 16px ;
    position: relative;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
}
.bottomPart{
    height: 56px;
    width: 100%;
    /* margin: 0; */
    gap: 15px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    box-sizing: border-box;
}

.inputTextArea::placeholder {
  color: #92929D;
  font-family: 'Segoe UI';
  font-size: 14px;
}
.inputTextArea:hover,
input.inputText:focus,.inputTextArea:focus {
    border: 1px solid #139DD9;
    transition: .2s;
    outline: none;
}

.inputTextArea{
    padding: 9px 12px 8px;
    width: 100%;
    height: 80px;
    border: 1px solid #CED6E0;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
    margin: 12px 0 0;
    resize: none;
    overflow-y: auto;
}

.docSectionWrap{
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 25px;
    border: 1px solid var(--brightBlue);
    box-sizing: border-box;
    border-radius: 10px;
}

/* DROP-ZONE */

.dropZone{
    width: 100%;
    height: 120px;
    padding: 10px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 2px dashed #CED6E0;
    box-sizing: border-box;
    transition: .8s;
}
.dropZoneActive{
    width: 100%;
    min-height: 120px;
    height: auto;
    padding: 10px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    border-radius: 10px;
    border: 2.5px dashed #139DD9;
    box-sizing: border-box;
    transition: .8s;
}
input[type="file"]{
    display: none;
}
.dropZone div, .dropZoneActive div{
    margin-bottom: 8px;
}
.btnPin{
    display: flex;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    padding: 10.5px 16px;
    width: 111px;
    height: 32px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    color: #139DD9;
    cursor: pointer;
}
.btnPin:hover{
    border: 1px solid #139DD9;
    background-color: #139DD9;
    color: #fff;
    transition: .2s;
}
.twoInLine{
    display: flex;
    flex-flow: row;
    width: 100%;
    gap: 15px;
}