.wrapContact{
    display: flex;
    width: 400px;
    position: relative;
}

.wrap_link{
    margin-top: 15px;
    padding-left: 15px;
    /*border-left: 1px solid rgb(109, 109, 109);*/
    /*border-left: 1px solid #d9d9d9;*/
    margin-left: 15px;
}

.wrap_link::after{
    content: ' ';
    position: absolute;
    left: 15px;
    width: 1px;
    top: 17px;
    height: 45px;
    background: #cacdd1;
    z-index: 10;
}

.korusne_img{
    display: flex;
    align-items: center;
    padding-left: 5px;
}

.korusne_title{
    font: 400 12px/14px "robotobold",Arial,sans-serif;
    color: var(--darkGray);
    text-align: left;
    text-transform: uppercase;
    margin-left: 5px;
    font-weight: 600;
}

.korusne_text{
    font: 400 11px/14px "robotolight",Arial,sans-serif;
    color: var(--darkGray);
    text-align: left;
    text-transform: none;
    padding-left: 10px;
}

a.korusne_link:link, a.korusne_link:visited, a.korusne_link:hover, a.korusne_link:active{
    text-decoration: none;
    color: var(--darkGray) !important;
}

.korusne_link{
    display: flex;
}

/* .contacts_link{
    text-decoration: none;
} */

.korusne_text span{
    font-weight: 600;
}

.contacts {
    display: none;
    position: absolute;
    top: 80px;
    background-color: #FFFFFF;
    box-shadow: 0 0 20px #f3f3f3;
    padding: 10px 20px 10px 10px;
}
.wrapContact:hover .contacts {
    display: block;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}