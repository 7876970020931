.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(1px) grayscale(60%);
}
.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popupBody {
    max-width: 518px;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
    border-radius: 10px;
    padding: 25px 12px 25px;
    position: relative;
}
.popupTitle{
    display: flex;
    width: 100%;
    font-weight: 600;
    font-size: 20px;
    margin: 0 0 20px 15px;
}
.closeButton {
    width: 18px;
    height: 18px;
    background: none;
    border: none;
    position: absolute;
    top: 25px;
    right: 25px;
    transform:rotate(45deg);
    -ms-transform:rotate(45deg);
    -webkit-transform:rotate(45deg);
    cursor: pointer;
    transition: all 0.2s;
 }
.closeButton:before, .closeButton:after {
    content: "";
    position: absolute;
    z-index: -1;
    background-color: #92929D;
 }
 .closeButton:hover:before, .closeButton:hover:after {
     background-color: #424242;
 }

.closeButton:before {
    width: 2px;
    margin-top: -50%;
    margin-left: -1px;
    height: 100%;
 }

.closeButton:after {
    height: 2px;
    margin-left: -50%;
    margin-top: -1px;
    width: 100%;
 }
 .emptyButton {
     height: 40px;
     width: 100%;
     background-color: #00AFF01a;
     border: none;
     border-radius: 10px;
     cursor: pointer;
     transition: all 0.2s;
 }
 .emptyButton:hover {
     opacity: 0.5;
 }

.list_wrap{
    height: 300px;
    overflow: scroll;
    overflow-x: hidden;
    border-bottom: 1px solid #E2E2EA;
}

.list {
    list-style-type: none;
    padding: 0;
    margin: 0 0 15px;
    border-bottom: 1px solid #E2E2EA;
}
.list_wrap .list{
    border-bottom: 0px solid #E2E2EA;
}

.list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.2s;
    padding: 2px 10px;
}
.list li:hover {
    background-color: #00AFF01a;
}

.orgName {
    width: 80%;
    padding: 10px;
    display: flex;
    /* margin-right: 12px; */
}
.orgName::before {
    content: '';
    margin-right: 12px;
    margin-top: 5px;
    height: 8px;
    min-width: 8px;
    border-radius: 50%;
    background-color: #CED6E0;
}
.orgId {
    margin-left: auto;
    color: #92929D;
    display: flex;
    align-items: center;
    gap: 15px;
    margin-left: auto;
    margin-right: 10px;
}
.buttonAddNew {
    background: none;
    border: none;
    color: #00AFF0;
    text-decoration: underline;
    transition: all 0.2s;
    cursor: pointer;
}
.buttonAddNew:hover {
    text-decoration: none;
}
.buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    margin-right: 10px;
    border-radius: 50%;
    transition: .2s;
}
.buttonWrapper:hover{
    background-color: #d4d4d6;
    transition: .2s;
}
.button {
    border: none;
    background: transparent;
    transition: all 0.2s;
    padding: 0;
}
.button:hover {
    opacity: 0.5;
}