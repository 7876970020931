.itemWrap{
    border: 1px dashed #E2E2EA;
    border-radius: 8px;
    padding: 10px 10px;
    position: relative;
}
.itemsWrap{
    display: flex; flex-flow: column; gap: 15px; width: 100%;
}
.closeWrap{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}
.closeBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}
.closeBtn:hover{
    background-color: #E2E2EA;
}
.addBtnWrap{
    display: flex;
    flex-flow: row-reverse;
    width: 100%;
}