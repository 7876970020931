.blockWrap{
    position: relative;
    width: 780px;
    height: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    box-sizing: border-box;
}
.infoBlock {
    position: relative;
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.leaseRulesPeriodBlock{
    position: relative;
    width: 100%;
    height: auto;
    background-color: #FAFAFB;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.infoBlockInner {
    position: relative;
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 0 20px 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.noborder{
    border: none;
}
.infoBlock h3 {
    margin: 0 0 16px 0;
}
.infoBlock div:last-child{
    margin-bottom:0;
}
.infoBlockTitle{
    width: 100%;
    height: auto;
    margin: 0 0 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.infoBlockInnerTitle{
    width: 80%;
    height: auto;
    margin: 0 0 8px;
}
.infoBlockInnerTitle h3{
    margin: 0;
}
.infoBlockTitle h3{
    margin: 0;
}
.blockTitle{
    width: 80%;
    height: auto;
    margin: 0 0 16px;
}
.generalInfo{
    border: none;
}
.titleBlock {
    width:75%;
    font-weight: 600;
    font-size: 14px;
    margin: 8px 0 16px;
}
ul.infoBlockList{
    margin: 0 0 16px;
    padding-left: 20px;
}
ul.infoBlockList li{
    margin: 0 0 8px;
    font-size: 14px;
    position:relative;
    list-style-type: none;
}
ul.infoBlockList li::before{
    position:absolute;
    left:-20px;
    top:-2px;
    content: '●';
    font-size:18px;
    color:#92929D;
}
.downLoadAllBtn{
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    height: 40px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color:#696974;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    margin-left: auto;
    cursor: pointer;
}
.downLoadAllBtn:hover{
    border: 1px solid #139DD9;
    transition: .2s;
}
.bottomBtnBlock{
    width: 100%;
    height: 40px;
    position: relative;
    margin: 20px 0 0 0;
    display: flex;
    flex-flow: row-reverse;
    gap: 20px;
}
.sendBtn {
    width: auto;
    height: 40px;
    padding: 10px;
    cursor:pointer;
    border-radius: 4px;
    border: 1px solid #139DD9;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100px;
    height: 40px;
    /* background-color: #139DD9; */
    color: #139DD9;
    margin: 20px 0 0 8px;
    transition: .2s;
}
.sendBtn:hover{
    background-color: #139DD9;
    border: 1px solid #139DD9;
    color: #FFF;
    transition: .2s;
}

