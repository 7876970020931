.wrapPage  {
    width: 1176px;
    height: auto;
    display: flex;
    flex-flow: row wrap;
    margin: 30px auto 0;
    padding-bottom: 25px;
    box-sizing: border-box;
}
.wrapContent {
    width: 1176px;
    height: auto;
    display: flex;
    justify-content: space-between;
    margin: 30px auto 0;
    box-sizing: border-box;
}

.navBlock{
    display: flex;
    flex-direction: column;
    width:100%;
}
ul.navUl {
    padding:0px;
    margin:10px 0;
    font-size: 14px;
    color: #171725;
}
ul.navUl li{
    display: inline-block;
    cursor: pointer;
}
.navUl > li:not(:last-child):after {
    content: ' / ';
    margin: 0 8px 0 8px;
}
.navUl li:last-child {
    color: #92929D;
}
.objNav {
    position: relative;
    height: 50px;
    margin-top: 32px;
    display:flex;
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    border-bottom: 1px solid #E2E2EA;
}
.objTitle h1{
    width: 75%;
}
.objTitle{
    position: relative;
}
.objNavItem {
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    cursor: pointer;
    box-sizing: border-box;
    padding-bottom: 25px;
    position: relative;
    padding: 0px;
    margin: 0px 25px;
    border-bottom: 5px solid transparent;
    transition: all 0.2s;
    user-select: none;
}
.objNavItem:hover {
    color: #139DD9;
    font-weight: 600;
    margin: 0px 25px;
}
.activeNavItem {
    color: #139DD9 !important;
    font-weight: 600;
    margin: 0px 25px;
    border-bottom: 5px solid #139DD9;
    user-select: none;
}
.activeNavItem.objNavItem::before{
    position: absolute;
    height: 5px;
    width: 100%;
    top: 45px;
    left: 0px;
    background-color: #139DD9;
    content: ' ';
    border-radius: 10px 10px 0 0;
}
.objNavItemInactive {
    font-weight: 600;
    font-size: 14px;
    color: #E2E2EA;
    cursor: default;
    box-sizing: border-box;
    padding-bottom: 25px;
    position: relative;
    padding: 0px;
    margin: 0px 25px;
    border-bottom: 5px solid transparent;
    transition: all 0.2s;
    user-select: none;
}
.titleButtonWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
}
.buttonWrapper {
    display: flex;
    gap: 16px;
}
.buttonDelete {
    padding: 10px;
    border: 1px solid #FFA047;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    transition: all .2s;
}
.buttonDelete:hover {
    background-color: #FFA047;
}
.buttonDelete:hover svg path {
    fill: #fafafb;
}