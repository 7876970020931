/* SELECT */
.inPutInfo{
    width: 100%;
    display: flex;
    flex-direction: column;
    position:relative;
    margin: 0 0 15px;
}
.inPutWrap{
    width: 100%;
    display: flex;
    position:relative;
}
.inPutSelect {
    width: 100%;
    height: 40px;
    border: 1px solid #CED6E0;
    box-sizing: border-box;
    border-radius: 4px;
    background-color:inherit;
    outline:none;
    color: #92929D;
    display: flex;
    padding: 9px 10px 0 10px;
    position: relative;
    cursor: pointer;
    transition: .5s;
}
.inPutSelect:after{
    position: absolute;
    border: 6px solid transparent; 
    border-top: 6px solid #92929D;
    top:18px;
    right: 8px;
    font-size: 16px;
    content: ' ';
    color: #92929D;
}
.focus.inPutSelect:after{
    top:12px;
    border: 6px solid transparent; 
    border-bottom: 6px solid #00AFF0;
}
.focus,
.inPutSelect:hover,
.inPutSelect:focus {
    border: 1px solid #00AFF0;
    transition: .2s;
}
.label {
    position: absolute;
    left: 8px;
    top: -5px;
    font-size: 11px;
    line-height: 9px;
    padding: 0px 5px;
    color: #92929D;
    background-color: #FFFFFF;;
    z-index: 2;
}
.focus + .label,
.inPutSelect:hover + .label,
.inPutSelect:focus + .label {
    color:#00AFF0;
    transition: .2s;
}
p.descript{
    font-size: 12px;
    line-height: 16px;
    color: #92929D;
    margin: 0 0 5px 10px;
    top: -12px;
    position: relative;
}
.inPutInfo.attent .inPutSelect{
    border: 1px solid #FFA047;
}
.inPutInfo.attent + p,
.inPutInfo.attent .label{
    color: #FFA047;
}
.error{
    color: #FFA047;
    font-weight: 600;
}
.complete{
    color: #171725;
}
.infoIcon{
    height: inherit;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    cursor: pointer;
    position: relative;
}
.infoIcon:hover .svgInfo{
    fill:#139DD9;
    transition: .2s;
}

.inputInfoBlock{
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    width: 440px;
    height:auto;
    min-width: 440px;
    box-sizing: border-box;
    top: 22px;
    right: 22px;
    background: #FAFAFB;
    border-radius: 10px;
    padding: 24px;
    z-index: 9999;
    background: #FAFAFB;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    cursor: default;
}
.inputInfoBlock p {
    color: #171725;
    padding: 2px 2px 0;
    margin: 0;
}
.bottomBtnBlock{
    width: 100%;
    height: auto;
    position: relative;
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.inputInfoBlockBtn {
    width: auto;
    height: 32px;
    padding: 10px 16px;
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #139DD9;
    color: #139DD9;
    cursor: pointer;
}
.inputInfoBlock a{
    cursor: pointer;
    font-style: italic;
    color: #696974;
}
.selectWrap{
    display: flex;
    flex-flow: column nowrap;
}
.bagesContainer{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: auto;
    overflow:auto;
    margin: 0 0 10px;
    box-sizing: border-box;
    overflow: hidden;
}

/* SELECT POPDOWN  */

.wrapperInfo,
.wrapper{
    position:  fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9990;
}
.selectPopdown{
    /* position:relative; */
    margin-top: 5px;
    left: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 12px 6px 12px 12px;
    box-sizing: border-box;

    /* z-index: 9998; */
    cursor:initial;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently */
}
.topPart{
    width: 100%;
    min-height: 40px ;
    height: auto;
    background: #00AFF0;
    opacity: 0.1;
    border-radius: 10px;
    margin: 0 0 10px;
    display: flex;
    box-sizing: border-box;
}
.content{
    /* max-height: 480px; */
    /* min-height: 320px; */
    height: calc(100% + 20px);
    /* overflow-y: auto; */
    /* overflow-x: hidden; */
    overflow-y: auto;
    padding: 0 0 5px 0;
    box-sizing: border-box;
}
.bottomPart{
    position:relative;
    height: 45px;
    border-top: 1px dashed #E2E2EA;
    padding: 8px 0 0;
    display: flex;
    box-sizing: border-box;
    justify-content: flex-end;
    align-items: center;
    margin: 5px 0 0 0;
}
.somePart{
    height: 35px;
    padding: 8px 8px;
    margin: 0 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    box-sizing: border-box;
}
.somePart:hover{
    background-color: #FAFAFB;
}
.somePart p{
    padding: 0 30px 0 0;
}
.somePart span{
    color: #92929D;
}
.btnDone{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    width: auto;
    height: 30px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    margin-left: 20px;
    color: #139DD9;
    cursor: pointer;
    font-weight: 600;
    font-size: 12px;
}
.btnDone:hover{
    color: #FFFFFF;
    background: #139DD9;
    transition: .2s;
}
.btnCancel{
    display: flex;
    font-weight: 600;
    font-size: 12px;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    width: auto;
    height: 30px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    box-sizing: border-box;
    margin-left: 20px;
    color: #696974;
    cursor: pointer;
}
.btnCancel:hover{
    color: #696974;
    background: #dfdfdf;
    transition: .2s;
}
.reset{
    color: #FFA047;
    text-decoration-line: underline;
    display: flex;
    cursor: pointer;
    margin-right: auto;
}