.title {
    font-weight: 600;
    font-size: 32px;
    line-height: 43px;
    color: #171725;
    margin: 16px 0 21px 0;
    flex: 3;
}
.sort{
    display: flex;
    flex: 1;
}
.tableContent{
    margin:12px auto 18px;
    width: 1176px;
    height: auto;
    top: 12px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    padding:4px;
    box-sizing: border-box;
}
.myRequestTable {
    width:100%;
    font-size: 14px;
}
.tabHead{
    position:relative;
    display:grid;
    grid-template-columns: 315px 255px 132px 255px 104px 86px;
    grid-template-rows: 38px;
    box-sizing: border-box;
    padding:12px 6px;
}
.thName,
.thStat,
.thStatus,
.thRent,
.thAction,
.thDeclaration,
.thActions{
    color: #696974;
    font-weight: 600;
    font-size: 14px;
    padding: 0 6px;
}
.thAction,
.thDeclaration,
.thActions{
    text-align:right;
}
.tableEmpty {
    text-align: center;
    padding: 24px;
    background-color: #FFFFFF;
}

.titleWrapper{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
}