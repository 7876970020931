.wrapNews{
    display: flex;
    width: 240px;
    position: relative;
}

.wrap_link{
    margin-top: 15px;
    padding-left: 15px;
    /*border-left: 1px solid rgb(109, 109, 109);*/
    /*border-left: 1px solid #d9d9d9;*/
    margin-left: 15px;
    position: relative;
}

.wrap_link::after{
    content: ' ';
    position: absolute;
    left: 0px;
    width: 1px;
    top: 0;
    /*bottom: 17px;*/
    height: 45px;
    background: #cacdd1;
    z-index: 10;
}

.korusne_img{
    display: flex;
    align-items: center;
    padding-left: 5px;
}

.korusne_title{
    font: 400 12px/14px "robotobold",Arial,sans-serif;
    color: #414141;
    text-align: left;
    text-transform: uppercase;
    margin-left: 5px;
    font-weight: 600;
}

.korusne_text{
    font: 400 11px/14px "robotolight",Arial,sans-serif;
    color: #666;
    text-align: left;
    text-transform: none;
    padding-left: 10px;
}

a.korusne_link:link, a.korusne_link:visited, a.korusne_link:hover, a.korusne_link:active{
    text-decoration: none;
}

.korusne_link{
    display: flex;
}

.popUp{
    display: none;
    position: absolute;
    top: 38px;
    left: 130px;
    z-index: 999;
    background-color: #fff;
    box-shadow: 3px 3px 4px 1px rgb(68, 68, 79, 0.3);
    /* box-shadow: 0 0 20px #f3f3f3; */
    min-width: 160px;
}

.popUp a{
    color: #6d6d6d;
}

.wrap_link:hover .popUp{
    display: block;
}

.popUp_menuElem{
    display: block;
    padding: 10px 15px;
    transition: all .2s;
    text-transform: none;
    cursor: pointer;
    text-decoration: none !important;
}
.popUp_menuElem:hover{
    background-color: #f3f3f3;
}
.popUp_menuElemActive{
    display: block;
    padding: 10px 15px;
    transition: all .2s;
    text-transform: none;
    cursor: pointer;
    text-decoration: none !important;
    background-color: var(--backgroundGray);
    font-weight: 600;
}
.popUp_menuElemEnd{
    border-top: 1px solid var(--lightGray);
    border-bottom: 0px solid #000;
    color: var(--black);
    font-weight: 600;
}

.svgArrow{
    margin-left: 25px;
}

@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}