.wrapper {
    padding: 24px;
    background-color: #FFFFFF;
    margin-bottom: 25px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
}
.title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 15px;
}
.subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 10px;
}
.buttonUpdate {
    /* position: absolute;
    right: 24px;
    top: 50px; */
    margin-left: 14px;
    position: relative;
    top: -12px;
    padding: 10px 16px;
    background-color: #FFFFFF;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    color: #696974;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonUpdate:hover {
    background-color: #E2E2EA;
}
.empty {
    width: 100%;
}
.infoWrapper {
    display: flex;
    flex-wrap: wrap;
}