.footer{
    position: relative;
    width: 100%;
    background: #646c7a;
}

.borderLine{
    width: 100%;
    /*height: 28px;*/
}

.footerTextBlock{
    padding-top: 10px;
    border-top: 1px solid #777d88;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}

.footerCopyright{
    color: #e3e3e3;
    width: 120px;
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    vertical-align: baseline;
}

.soclinc{
    color: #e3e3e3;
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    vertical-align: baseline;
    text-align: right;
}

.footerMenu{
    display: flex;
    font: 400 13px/18px "robotolight",Arial,sans-serif;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 50%;
}
.footerMenu div{
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.soclinc a:link,
.soclinc a:visited,
.soclinc a:hover,
.soclinc a:active,
.footerMenu a:link,
.footerMenu a:visited,
.footerMenu a:hover,
.footerMenu a:active{
    color: #e3e3e3;
    text-decoration: none;
}

.footerMenu a:hover{
    cursor: pointer;
}

/*@media (min-width: 320px){
    .footer_wrap{
        width: 300px;
        margin: auto;
        padding: 20px 0 10px;
        color: #e3e3e3;
        text-transform: uppercase;
    }
    .footerCopyright{
        width: 100px
    }
    .footerMenu{
        width: calc(100% - 200px);
    }
    .footerMenu div{
        width: 100%;
    }
    .soclinc{
        width: 100px
    }
}
@media (min-width: 730px){
    .footer_wrap{
        width: 710px;
        margin: auto;
        padding: 20px 0 10px;
        color: #e3e3e3;
        text-transform: uppercase;
    }
    .footerCopyright{
        width: 100px
    }
    .footerMenu{
        width: calc(100% - 200px);
    }
    .footerMenu div{
        width: 30%;
    }
    .soclinc{
        width: 100px
    }
}
@media (min-width: 960px){
    .footer_wrap{
        width: 940px;
        margin: auto;
        padding: 20px 0 10px;
        color: #e3e3e3;
        text-transform: uppercase;
    }
    .footerCopyright{
        width: 198px
    }
    .footerMenu{
        width: calc(100% - 396px);
    }
    .footerMenu div{
        width: 30%;
    }
    .soclinc{
        width: 198px
    }
}
@media (min-width: 1280px){*/
    .footer_wrap{
        /*width: 1260px;*/
        width: 1200px;
        margin: auto;
        padding: 20px 0 10px;
        color: #e3e3e3;
        text-transform: uppercase;
    }
    /* .footerCopyright{
        width: 120px
    } */
    /* .footerMenu{
        width: 50%;
    } */
    /* .footerMenu div{
        width: calc(20% - 20px);
    } */
    .soclinc{
        width: 198px
    }
/*}
@media (min-width: 1440px){
    .footer_wrap{
        width: 1320px;
        margin: auto;
        padding: 20px 0 10px;
        color: #e3e3e3;
        text-transform: uppercase;
    }
    .footerCopyright{
        width: 198px
    }
    .footerMenu{
        width: calc(100% - 396px);
    }
    .footerMenu div{
        width: calc(20% - 20px);
    }
    .soclinc{
        width: 198px
    }
}
@media (min-width: 1600px){
    .footer_wrap{
        width: 1480px;
        margin: auto;
        padding: 20px 0 10px;
        color: #e3e3e3;
        text-transform: uppercase;
    }
    .footerCopyright{
        width: 198px
    }
    .footerMenu{
        width: calc(100% - 396px);
    }
    .footerMenu div{
        width: calc(20% - 20px);
    }
    .soclinc{
        width: 198px
    }
}
@media (min-width: 1920px){
    .footer_wrap{
        width: 1800px;
        margin: auto;
        padding: 20px 0 10px;
        color: #e3e3e3;
        text-transform: uppercase;
    }
    .footerCopyright{
        width: 198px
    }
    .footerMenu{
        width: calc(100% - 396px);
    }
    .footerMenu div{
        width: calc(20% - 20px);
    }
    .soclinc{
        width: 198px
    }
}*/