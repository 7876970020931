/* JUST INPUT */
.inPutInfo{
    width: 100%;
    display: flex;
    position:relative;
    margin: 0 0 15px;
}
.inPutWrap{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    position:relative;
}
.inputText{
    width: 100%;
    height: 40px;
    font-size: inherit;
    background-color: inherit;
    outline: none;
    border: 1px solid #CED6E0;
    border-radius: 4px;
    position: relative;
    /* color: #92929D; */
    color: #171725;
    padding: 0px 10px 0 10px;
    box-sizing: border-box;
}
input.inputText::placeholder{
    color:#E2E2EA;
}
.focus,
.inputText:hover,
.inputText:focus {
    border: 1px solid #00AFF0;
    transition: .2s;
}
.disabled{
        width: 100%;
        height: 40px;
        font-size: inherit;
        background-color: inherit;
        outline: none;
        border: 1px solid #CED6E0;
        border-radius: 4px;
        position: relative;
        color: #92929D;
        padding: 0px 10px 0 10px;
        box-sizing: border-box;
}
.complete{
    color: #171725;
}
.half{
    width:49%;
}
.center{
    align-items: center;
}
label.label{
    position: absolute;
    max-width:75%;
    left: 8px;
    top: -6px;
    font-size:11px;
    line-height: 9px;
    padding: 1px 5px;
    color: #92929D;
    background-color: #FFFFFF;
    z-index: 2;
    transition: .2s;
    user-select: none;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}
.focus label,
.inputText:hover + label,
.inputText:focus + label {
    color:#00AFF0;
    transition: .2s;
}
p.descript{
    font-size: 12px;
    line-height: 16px;
    color: #92929D;
    margin: -12px 0 10px 10px;
}
.inPutInfo.attent input {
    border: 1px solid #FFA047;
}
.inPutInfo.attent + p,
.inPutInfo.attent > .label{
    color: #FFA047;
}

.infoIcon{
    height: inherit;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    cursor: pointer;
    position: relative;
}
.infoIcon:hover .svgInfo{
    fill:#139DD9;
    transition: .2s;
}
.inputInfoBlock{
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    width: 440px;
    height:auto;
    min-width: 440px;
    box-sizing: border-box;
    top: 22px;
    right: 22px;
    background: #FAFAFB;
    border-radius: 10px;
    padding: 24px;
    z-index: 9999;
    background: #FAFAFB;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    cursor: default;
}
.noDisplay{
    display:none;
}
.inputInfoBlock p {
    color: #171725;
    padding: 2px 2px 0;
    margin: 0;
}
.bottomBtnBlock{
    width: 100%;
    height: auto;
    position: relative;
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.inputInfoBlockBtn {
    width: auto;
    height: 32px;
    padding: 10px 16px;
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #139DD9;
    color: #139DD9;
    cursor: pointer;
}
.inputInfoBlock a{
    cursor: pointer;
    font-style: italic;
    color: #696974;
}
.wrapperInfo {
    position:  fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9990;
}