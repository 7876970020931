.orderWrap {
    display: flex;
    flex-flow: column;
    width: 100%;
}
.twoInLine{
    display: flex;
    flex-flow: row;
    width: 100%;
    gap: 15px;
}
.documentsWrap{
    display: flex;
    flex-flow: column;
    width: 100%;
    gap: 15px;
    border-top: 1px solid var(--borderGray);
    padding: 10px 0 0;
}
.documentsList{
    display: flex;
    flex-flow: column;
    width: 100%;
    gap: 10px;
}
.documentsAddButton, .postButton{
    display: flex;
    flex-flow: row-reverse;
    width: 100%;
}
.postButton{
    border-top: 1px solid var(--borderGray);
    padding: 10px 0 0;
}
.editButton{
    display: flex;
    flex-flow: row-reverse;
    width: 100%;
    margin: 0 0 20px;
}