/* JUST inPutTextArea */
.inPutTextArea{
    width: 100%;
    display: flex;
    position:relative;
    margin: 0 0 15px;
}
.inPutWrap{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    position:relative;
    padding-bottom: 15px;
}
.textLabelWrap{
    display: flex;
    flex-flow: column;
    width: 100%;
}
textarea{
    width: 100%;
    height: 75px;
    min-height: 60px;
    font-size: 14px;
    font-family: inherit;
    background-color: inherit;
    outline: none;
    border: 1px solid #CED6E0;
    border-radius: 4px;
    position: relative;
    color: #171725;
    padding: 8px 10px 0 10px;
    box-sizing: border-box;
    resize: vertical;
    margin-top: -6px;
}
textarea:disabled{
    color: var(--lightGray);
    border: 1px solid #CED6E0;
}
/* textarea::placeholder{
    color:#E2E2EA;
} */
.focus,
textarea:hover:not([disabled]),
textarea:focus {
    border: 1px solid #00AFF0;
    transition: .2s;
}
.half{
    width:49%;
}
.center{
        align-items: center;
}

.select{
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.59 6.37'%3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:%2392929d;fill-rule:evenodd;%7d%3c/style%3e%3c/defs%3e%3cg id='Слой_2' data-name='Слой 2'%3e%3cg id='Слой_1-2' data-name='Слой 1'%3e%3cpath class='cls-1' d='M9.09,0a.5.5,0,0,1,.39.81L5.18,6.18a.5.5,0,0,1-.78,0L.11.81A.5.5,0,0,1,.5,0Z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .7em top 55%, 0 0;
    background-size: .65em auto, 100%;
    cursor: pointer;
}
label.label{
    /* position: absolute; */
    height: auto;
    max-width:80%;
    width: fit-content;
    margin-left: 8px;
    /* top: -6px; */
    font-size: 11px;
    line-height: 11px;
    padding: 3px 5px;
    color: #92929D;
    background-color: #FFFFFF;;
    z-index: 2;
    transition: .2s;
    user-select: none;
    /* white-space: nowrap;  */
    overflow: hidden; 
    text-overflow: ellipsis;
}
.lableContent{
    display: none;
    top: 0;
    left: 0;
}
label:hover .lableContent{
    display: absolute;
}
textarea:hover + label,
textarea:focus + label {
    color:#00AFF0;
    transition: .2s;
}
.descript{
    font-size: 12px;
    line-height: 16px;
    color: #92929D;
    margin: -12px 0 10px 10px;
}
.inPutTextArea.attent textarea {
    border: 1px solid #FFA047;
}
.inPutTextArea.attent + p,
.inPutTextArea.attent > .label{
    color: #FFA047;
}
.complete{
    color: #171725;
}
.infoIcon{
    height: inherit;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    cursor: pointer;
    position: relative;
}
.infoIcon:hover .svgInfo{
    fill:#139DD9;
    transition: .2s;
}
.inputInfoBlock{
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    width: 440px;
    height:auto;
    min-width: 440px;
    box-sizing: border-box;
    top: 50%;
    right: 22px;
    background: #FAFAFB;
    border-radius: 10px;
    padding: 24px;
    z-index: 9999;
    background: #FAFAFB;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    cursor: default;
}
.noDisplay{
    display:none;
}
.inputInfoBlock p {
    color: #171725;
    padding: 2px 2px 0;
    margin: 0;
}
.bottomBtnBlock{
    width: 100%;
    height: auto;
    position: relative;
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.inputInfoBlockBtn {
    width: auto;
    height: 32px;
    padding: 10px 16px;
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #139DD9;
    color: #139DD9;
    cursor: pointer;
}
.inputInfoBlock a{
    cursor: pointer;
    font-style: italic;
    color: #696974;
}
.wrapperInfo {
    position:  fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9990;
}