.blockWrap{
    position: relative;
    width: 780px;
    height: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 15px;
    padding: 15px 0;
}
.infoBlock {
    width: 100%;
    height: auto;
    /* border: 1px solid #E2E2EA; */
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    /* margin: 20px 0 0 0; */
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.docContentWrap{
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    /* gap: 15px; */
}
.addButtonBlock{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}
.empty, .title{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #696974;
    height: 200px;
}
.title{
    height: 60px;
}
.docList{
    display: flex;
    flex-flow: column;
    gap: 15px;
}