.createObject {
    position: relative;
    width: 780px;
    height: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
}

.buttonWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
}
.buttonCancel {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #696974;
    padding: 10px 16px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonCancel:hover {
    background-color: #E2E2EA;
}
.buttonSave {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 10px 16px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    background-color: #139DD9;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonSave:hover {
    background-color: transparent;
    color: #139DD9;
}
.buttonNext {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #139DD9;
    padding: 10px 16px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonNext:hover {
    background-color: #139DD9;
    color: #FFFFFF;
}