.sideBlock {
    width: 370px;
    min-height: 70vh;
    /* height: 1000px; */
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0 0 0 24px;
}
.sideLinked .desc{
    font-size: 10px;
    color: #696974;
    margin: 15px 0 0;
}
.link{
    text-decoration-line: underline;
    color: #00AFF0;
    margin: 3px 0 0;
    cursor: pointer;
}
.stepBlock{
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row nowrap;
    box-sizing: border-box;
    margin: 25px 0 5px 0;
}
.stepIcon{
    width: 36px;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    box-sizing: border-box;
    padding-top: 0px;
}
.stepInfo{
    width: 266px;
    padding-left: 24px;
    box-sizing: border-box;
}
.stepInfo h3{
    margin: 0px 0 5px 0;
    width: 266px;
}
.stepInfo h3.inactive{
    color:#92929D;
    font-weight: 600;
}
.stepInfo p{
    font-size: 14px;
    color: #92929D;
    margin: 0px 0 0 0;
}
.stepInfo ul{
    font-size: 14px;
    color: #92929D;
    list-style-type: '- ';
}
.stepInfo li{
    margin: 0 0 0 -30px;
}
.sidelotImage{
    width: 100%;
}
.sidelotImage img{
    width: 150px;
    height: 150px;
    border-radius: 4px;
    cursor: pointer;
}
.lotStartPriceBlock{
    color:#696974;
    font-weight: 600;
    font-size: 14px;
    margin: 17px 0 20px;
}
.lotStartPriceBlock p{
    margin: 0 0;
}
p.lotStartPrice{
    font-weight: 600;
    font-size: 24px;
    color: #171725;
    margin: 0 ;
}
.lotStartPrice span{
    font-size: 14px;
    color: #171725;
}
.lotInfoBlock{
    color:#696974;
    font-weight: 600;
    font-size: 14px;
    margin: 17px 0 20px;
}
.lotInfoBlock p{
    margin: 0;
}
.lotInfo{
    font-size: 14px;
    color: #171725;
    font-weight: 400;
    margin: 0;
}

p.statusGreen{
    font-weight: 600;
    text-transform: uppercase;
    color: #00B191;
}
p.statusGrey{
    font-weight: 600;
    text-transform: uppercase;
    color: #92929D;
}
p.statusBlack{
    font-weight: 600;
    text-transform: uppercase;
    color:#171725;
}
.aucStatus{
    color:#696974;
    font-weight: 600;
    font-size: 14px;
    margin: 20px 0 15px;
}
.aucStatus p {
    margin-bottom: 0px;
}
p.lotStatus{
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 8px;
}
.aucStatus{
    color:#696974;
    font-weight: 600;
    font-size: 14px;
    margin: 20px 0 15px;
}
.aucStatus p {
    margin-bottom: 0px;
}
.lotStatus{
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 8px;
}
.objectType {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}
.value {
    margin: 32px 0;
}
.valueText {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #696974;
}
.valueAmount {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #171725;
}
.valueCurrency {
    font-size: 14px;
    line-height: 19px;
}
.relatedObj{
    display: flex;
    margin-top: 40px;
    flex-flow: column;
    border-radius: 8px;
    padding: 15px;
    background-color: var(--borderGray);
    gap: 10px;
}
.relatedObj{
    font-weight: 600;
    color: #171725;
}