.categoryWrapMain{
    width: 100%;
    background-color: #FAFAFB;
    padding-top: 0px;
    padding-bottom: 0px;
}
.categoryWrap{
    width: 1176px;
    height: 44px;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    background-color: #FFF;
    padding: 0 12px;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 0 auto;
}

.category{
    font-family: 'Roboto';
    height: 44px;
    width:auto;
    max-width:330px;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 16px;
    color: #171725;
    padding: 5px 20px;
    margin: 0 auto;
    /* background-color: rgb(165, 34, 34); */
    box-sizing: border-box;
    cursor:pointer;
}
.category:hover:not(.active){
        background-color: var(--disabledGrey);
}
.activeCategory{
    font-family: 'Roboto';
    height: 44px;
    width:auto;
    max-width:330px;
    display: flex;
    text-align: center;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    padding: 5px 20px;
    margin: 0 auto;
    background: var(--darkGray);
    box-sizing: border-box;
    cursor:pointer;
}

.active {
    background: #171725;
}