.inputValueBlock{
    width: 100%;
    margin: 0 0 20px;
    position: relative;
    
}
.valueBlock{
    display: flex;
    flex-flow: row nowrap;
    margin: 10px 0 0;
    align-items: center;
}
.currency{
    width: 88px;
    height: 40px;
    background: #FAFAFB;
    border-radius: 4px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.59 6.37'%3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:%2392929d;fill-rule:evenodd;%7d%3c/style%3e%3c/defs%3e%3cg id='Слой_2' data-name='Слой 2'%3e%3cg id='Слой_1-2' data-name='Слой 1'%3e%3cpath class='cls-1' d='M9.09,0a.5.5,0,0,1,.39.81L5.18,6.18a.5.5,0,0,1-.78,0L.11.81A.5.5,0,0,1,.5,0Z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 15% top 55%, 0 0;
    background-size: .65em auto, 100%;
    color: #BFBFC6;
    cursor: pointer;
    padding: 10px 15px;
    box-sizing: border-box;
    position: relative;
}
.currency::before{
    display: flex;
    content: 'Валюта';
    position: absolute;
    width: 44px;
    height: 21px;
    left: 8px;
    top:-10px;
    font-size: 10px;
    line-height: 12px;
    color: #92929D;
    background-color:  #FFFFFF;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border-radius: 11px;
}
.currency:hover::before{
    color:#139DD9;
    transition: .2s;
}
.curItem{
    width: 100%;
    list-style-type: none;
    background-color: inherit;
    cursor: pointer;
    color: #BFBFC6;
}
.curItem:hover{
    color: #171725;
}
.curList{
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 100%;
    background-color: inherit;
    position: absolute;
    z-index: 9999;
    padding: 10px 15px;
    box-sizing: border-box;
    top: 42px;
    left:0px;
    box-shadow: 3px 3px 4px 1px rgb(68, 68, 79, 0.3);
    border-radius: 4px;
}
.inPut {
    width: 175px;
    height: 40px;
    border: 1px solid #CED6E0;
    box-sizing: border-box;
    border-radius: 4px;
    background-color:inherit;
    outline:none;
    display: flex;
    padding: 9px 10px;
    position: relative;
    margin: 0 0 0 10px;
    color: #171725;
}
.focus,
.inPut:hover,
.inPut:focus {
    border: 1px solid #00AFF0;
    transition: .2s;
}
.valueBlock{
    display: flex;
    flex-flow: row nowrap;
    margin: 10px 0 0;
    align-items: center;
}
.complete{
    color: #171725;
}
.infoIcon{
    height: inherit;
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    cursor: pointer;
    position: relative;
}
.infoIcon:hover .svgInfo{
    fill:#139DD9;
    transition: .2s;
}
.chBox{
    width: 100%;
    height: 34px;
    display: flex;
    flex-flow: row nowrap;
    margin: 10px 0 10px 10px;
    /* align-items: center; */
}
.checkBox{
    width: 14px;
    height: 14px;
    margin: 8px 0;
    border: 1px solid #B5B5BE;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
}
.checkBoxOn{
    width: 14px;
    height: 14px;
    margin: 8px 0;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.70251 2.7801C1.30947 2.39211 0.676317 2.39621 0.28833 2.78925C-0.0996578 3.1823 -0.095558 3.81545 0.297487 4.20344L2.83844 6.7117C3.25647 7.12434 3.93843 7.08954 4.31227 6.63648L8.43799 1.63648C8.78949 1.21049 8.7291 0.580209 8.30311 0.228708C7.87713 -0.122792 7.24685 -0.0624085 6.89535 0.363579L3.46543 4.52033L1.70251 2.7801Z' fill='white'/%3e%3c/svg%3e ");
    background-color: #0062FF;
    background-repeat: no-repeat;
    background-position: right 40% top 50%, 0 0;
    border: 1px solid #0062FF;
}
.chBoxLabel{
    display: flex;
    margin: 8px 0 0 10px;
    width: 95%;
}
.noDisplay{
    display:none;
}
.inputInfoBlock{
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    width: 440px;
    height:auto;
    min-width: 440px;
    box-sizing: border-box;
    top: 22px;
    left: 22px;
    background: #FAFAFB;
    border-radius: 10px;
    padding: 24px;
    z-index: 9999;
    background: #FAFAFB;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    cursor: default;
}
.inputInfoBlock p {
    color: #171725;
    padding: 2px 2px 0;
    margin: 0;
}
.bottomBtnBlock{
    width: 100%;
    height: auto;
    position: relative;
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.inputInfoBlockBtn {
    width: auto;
    height: 32px;
    padding: 10px 16px;
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #139DD9;
    color: #139DD9;
    cursor: pointer;
}
.inputInfoBlock a{
    cursor: pointer;
    font-style: italic;
    color: #696974;
}
.wrapper,
.wrapperInfo {
    position:  fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9990;
}
