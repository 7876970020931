.infoBlock {
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 20px 0;
    padding: 24px;
    display: flex;
    flex-flow: column wrap;
}
.itemsListContent{
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
    width: 100%;
}
.infoBlockTitle{
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0 20px;
}
.buttonsBlock{
    display: flex;
    flex: 1;
    flex-flow: row-reverse;
    margin-top: 20px;
    padding-top:10px;
    /* border-top: 1px solid var(--lightGray); */
}