.blockHalf {
    /* width:50%; */
    margin: 0 0 16px;
    padding: 0 30px 0 0;
    box-sizing: border-box;
    word-break: break-word;
}
.blockHalf:last-child {
    margin: 0;
}
.description {
    font-family: 'Segoe UI';
    font-size: 11px;
    line-height: 14px;
    color: #696974;
    margin: 2px 0 0;
    user-select: none;
}
.data{
    margin: 4px 0 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #171725;
}
li{
    list-style: none;
}