.wrapPage  {
    width: 1176px;
    height: auto;
    display: flex;
    flex-flow: row wrap;
    margin: 30px auto 0;
    padding: 0px 30px 10px 10px;
    box-sizing: border-box;
}
.navBlock{
    display: flex;
    flex-direction: column;
    width:100%;
}
.navBlock ul {
    padding:0px;
    margin:10px 0;
    font-size: 14px;
    color: #171725;
}
.navBlock ul li{
    display: inline-block;
    cursor: default;
}
.navBlock ul li:first-child{
    cursor: pointer;
}
.navBlock ul > li:not(:last-child):after {
    content: ' / ';
    margin: 0 8px 0 8px;
}
.navBlock ul li:last-child {
    color: #92929D;
}
/* .liTitle{
    max-width: 400px;
    max-height: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
} */
.objTitle{
    line-height: 28px;
}
.objContent {
    width: 780px;
    height: auto;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.objContent .actItem:last-child{
    margin-bottom:0;
}
.objNav {
    position: relative;
    height: 50px;
    margin-top: 32px;
    display:flex;
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    border-bottom: 1px solid #E2E2EA;
}
.objNavItem {
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    cursor: pointer;
    box-sizing: border-box;
    padding-bottom: 25px;
    position: relative;
    height: 40px;
    padding:0px;
    margin: 0px 25px;
}
.activeNavItem {
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    cursor: pointer;
    box-sizing: border-box;
    padding-bottom: 25px;
    position: relative;
    height: 40px;
    padding:0px;
    margin: 0px 25px;
    color: #139DD9;
}
.activeNavItem::before{
    position: absolute;
    height: 5px;
    width: 100%;
    top: 45px;
    left: 0px;
    background-color: #139DD9;
    content: ' ';
    border-radius: 10px 10px 0 0;
}
/* leftBlock */ 
.mainBlock {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}

/* ASIDE */
.sideBlock {
    width: 345px;
    height: 1000px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    padding: 24px 0 0 24px;
    box-sizing: border-box;
}
.sidelotImage{
    display: flex;
    width: 150px;
    height: 150px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 31.96'%3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:%23139dd9;%7d.cls-2%7bfill:%23fcdc16;%7d%3c/style%3e%3c/defs%3e%3cg id='Шар_2' data-name='Шар 2'%3e%3cg id='Шар_1-2' data-name='Шар 1'%3e%3cg id='Шар_2-2' data-name='Шар 2'%3e%3cpolygon class='cls-1' points='0 18.19 34.94 2.78 34.94 16.67 0 31.96 0 18.19'/%3e%3cpolygon class='cls-2' points='37.31 6.06 49.95 0 50 14.4 37.31 20.21 37.31 6.06'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
    background-repeat: no-repeat;
    overflow: hidden;
    justify-content: center
}
.sidelotImage img{
    width: 150px;
    height: auto;
    border-radius: 4px;
    cursor: pointer;
    object-fit: cover;
}
/* STATUS */
.aucStatus{
    color:#696974;
    font-weight: 600;
    font-size: 14px;
    margin: 20px 0 15px;
}
.aucStatus p {
    margin-bottom: 0px;
}
p.lotStatus{
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 8px;
}
p.colorLightGrey{
    color: #92929D;
}
p.colorDark{
    color: #171725;
}
p.colorLightBrown{
    color: #A68800;
}
p.colorGreen{
    color: #00B191;
}
p.colorMeedllGrey{
    color: #696974;
}
.lotStartPriceBlock{
    color:#696974;
    font-weight: 600;
    font-size: 14px;
    margin: 17px 0 20px;
}
.lotStartPriceBlock p{
    margin: 0 0;
}
p.lotStartPrice{
    font-weight: 600;
    font-size: 24px;
    color: #171725;
    margin: 0 ;
}
.lotStartPrice span{
    font-size: 14px;
    color: #171725;
}
.lotInfoBlock{
    color:#696974;
    font-weight: 600;
    font-size: 14px;
    margin: 17px 0 20px;
}
.lotInfoBlock p{
    margin: 0;
}
.lotInfo{
    font-size: 14px;
    color: #171725;
    font-weight: 400;
    margin: 0;
}
.sideLinked .desc{
    font-size: 10px;
    color: #696974;
    margin: 15px 0 0;
}
.link{
    text-decoration-line: underline;
    color: #00AFF0;
    margin: 3px 0 0;
    cursor: pointer;
}
/*  IMAGE POPUP  */
.imageWrapper{
    display: flex;
    position: relative;
    margin: 0 15px;
    width:auto;
    height: auto;
    max-height: 800px;
    padding:8px;
    z-index: 999;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    align-items: center;
    justify-content: center;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;     
}
.image{
    position: relative;
    display: flex;
    z-index: 999;
    align-items: center;
    justify-content: center;
    max-height: 800px;
}
.image img{
    max-height: 800px;
}
@media (max-height: 768px){
    .image{
    position: relative;
    display: flex;
    z-index: 999;
    align-items: center;
    justify-content: center;
    max-height: 440px;
}
.image img{
    max-height: 440px;
}
}
.imageWrapperBg{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    /* background:rgba(0, 0, 0, .8); */
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px) grayscale(80%) brightness(20%);
}
.noDisplay{
    display: none;
}
.popUpClose{
    position: absolute;
    top: 0px;
    right: -50px;
    font-size: 64px;
    cursor: pointer;
    color:#ffffff;
    opacity: .3;
}
.popUpClose:hover{
    opacity: 1;
    transition: .2s;
}
.imageArrow{
    height: 300px;
    width:40px;
    display: flex;
    font-size: 62px;
    cursor: pointer;
    color:#ffffff;
    align-items: center;
    justify-content: center;
    opacity: .3;
}
.imageArrowPlaceholder{
    height: 300px;
    width:40px;
    display: flex;
    font-size: 62px;
    color:#ffffff;
    align-items: center;
    justify-content: center;
    opacity: .3;
}
.imageArrow:hover{
    opacity: 1;
    transition: .2s;
}
.nothing{
    width: 100%;
    height: 300px;
    display: flex;
    font-size: 28px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
}