.badgeElem{
    /* width: auto; */
    max-width: 90%;
    padding: 2px 6px 2px 4px;
    background: #FAFAFB;
    border-radius: 100px;
    font-family: 'Roboto';
    font-size: 11px;
    color: #92929D;
    cursor: default;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 2px 2px 0;
    box-sizing: border-box;
}
.badgeElem:hover{
    background-color: rgba(6, 100, 141, 0.1);
    color: #06648D;
    transition: .2s
}
.badgeElem:hover .badgeElem_title{
    color: #06648D;
    transition: .2s
}
.badgeElem_title{
    width: auto;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
}
.closeSvg{
    display: flex;
    cursor: pointer;
}
.badgeElem svg{
    margin-left: 11.33px;
}
.badgeElem:hover .svgIcon{
    transition: .2s;
    fill: #06648D;
}
.countStatus{
    border-left: 1px dashed #E2E2EA;
    padding-left: 6.5px;
    padding-right: 6.5px;
    margin-left: 6.5px;
}