/* DOC LIST */

.docList{
    position:relative;
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    /* margin: 8px 0; */
    padding: 24px;
    display: flex;
    flex-flow: row nowrap;
}
.docBlockLft{
    display: flex;
    width: 60%;
    font-size: 14px;
    margin-right: 18px;
    padding-right: 10px;
    box-sizing: border-box;
}
.docBlockRgt{
    display: flex;
    width: 25%;
    font-size: 14px;
    margin-right: 18px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
.iconsBlock{
    /* position: absolute; */
    /* right:20px; */
    display: flex;
    justify-content: flex-end;
    width: 20%;
    box-sizing: border-box;
    align-items: center;
}
.iconsBlock svg{
    cursor: pointer;
}
/* .infoWrap{
    display: flex;
    position: relative;
} */
.svgIconInfo:hover .svgIcon,
.svgIconDownld:hover .svgIcon {
    position: relative;
    fill:#139DD9;
    transition:.2s;
}
.svgIconInfoActive .svgIcon{  fill:#139DD9; transition:.2s; }
.svgIconView:hover .svgIcon{
    stroke: #139DD9;
    fill:#139DD9;
    transition:.2s;
}
.svgIconDelete{
    /* fill:#139DD9; */
    transition:.2s;
}
.deleteBtn {
    display: flex;
    border-radius: 50%;
    padding: 5px;
    transition: .2s;
    cursor: pointer;
}
.deleteBtn:hover{
    background-color: #FFA047;
    transition: .2s;
}
.deleteBtn:hover .svgIconDelete{
    fill:#FFF;
    transition:.2s;
}

/* INFO MODAL */

.inputInfoBlock{
    position: absolute;
    display: flex;
    flex-flow: column nowrap;
    width: 340px;
    height:auto;
    min-width: 340px;
    box-sizing: border-box;
    bottom: 66px;
    right: 39px;
    background: #ffffff;
    border-radius: 10px;
    padding: 24px;
    z-index: 9999;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    cursor: default;
}
.inputInfoBlock::after{
    position: absolute;
    content: ' ';
    bottom: -10px;
    right: 20px;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background-color: inherit;
    box-shadow: 5px 4px 4px 0px rgb(0 0 0 / 5%);
    z-index: 999;
}
.modDocTitle{
    font-size: 14px;
    font-weight: 600;
    color: #171725;
    margin: 0 0 10px;
    word-break: break-all;
}
.infoField{
    color: #696974;
}
.infoField span{
    color: #171725;
    margin-left: 20px;
}
.noDisplay{
    display:none;
}
.inputInfoBlock p {
    color: #171725;
    padding: 2px 2px 0;
    margin: 0;
}
.bottomBtnBlock{
    width: 100%;
    height: auto;
    position: relative;
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.inputInfoBlock a{
    cursor: pointer;
    font-style: italic;
    color: #696974;
}
.wrapperInfo {
    position:  fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9990;
}