.navBlock{
    display: flex;
    flex-flow: column nowrap;
    width:100%;
    user-select: none;
}
ul.navUlList {
    padding:0px;
    margin:10px 0;
    font-size: 14px;
    color: #171725;
}
ul.navUlList li{
    display: inline-block;
    cursor: default;
}
ul.navUlList li:first-child{
    cursor: pointer;
}
ul.navUlList > li:not(:last-child):after {
    content: ' / ';
    margin: 0 8px 0 8px;
}
ul.navUlList li:last-child {
    color: #92929D;
}
.objTitle{
    display: flex;
    font-size: 36px;
    font-weight: 600;
    flex: 8;
}
.objNav {
    position: relative;
    height: 50px;
    margin-top: 20px;
    display:flex;
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    border-bottom: 1px solid #E2E2EA;
}
.objNavItem,.inactiveObjNavItem {
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    cursor: pointer;
    box-sizing: border-box;
    padding-bottom: 25px;
    position: relative;
    height: 40px;
    padding:0px;
    margin: 0px 25px;
}
.inactiveObjNavItem{
    color: #92929D;
    cursor: default;
}
.activeNavItem {
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    cursor: pointer;
    box-sizing: border-box;
    padding-bottom: 25px;
    position: relative;
    height: 40px;
    padding:0px;
    margin: 0px 25px;
    color: #139DD9;
}
.activeNavItem::before{
    position: absolute;
    height: 5px;
    width: 100%;
    top: 45px;
    left: 0px;
    background-color: #139DD9;
    content: ' ';
    border-radius: 10px 10px 0 0;
}
.titleBlock{
    display: flex;
    flex-flow: column;
    width: 100%;
}
.titleAndSend{
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: space-between;
}
.sendButton{
    display: flex;
    flex: 3;
    flex-flow: row nowrap;
    justify-content: flex-end;
    width: 100%;
    gap: 15px;
}
.pathString{
    display: flex;
    flex-flow: row;
}