.myActionsTable .objTabItem{
    grid-template-columns: 471px 225px 132px 132px 104px 86px;
} 
.objTabItem{
    position:relative;
    display:grid;
    grid-template-columns: 315px 255px 132px 255px 104px 86px;
    grid-template-rows: auto;
    background-color: #FFFFFF;
    border-radius: 4px;
    padding:12px 6px;
    margin-bottom: 8px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    transition: .2s;
}
.objTabItem:hover{
    border: 1px solid #E2E2EA;
    transition: .2s;
}
.tdName{
    font-weight: 600;
    color: #171725;
    padding: 0 6px;
    cursor: pointer;
}
.tdStat{
    font-weight: 600;
    color: #696974;
    padding: 0 6px;
}
.tdStatus{
    font-weight: 600;
    color: #696974;
    text-transform: uppercase;
    padding: 0 6px;
}
.tdRent{
    color: #696974;
    padding: 0 6px;
}
.tdAction{
    text-align:right;
    color: #696974;
    padding: 0 6px;
}
.tdDeclaration{
    text-align:right;
    color: #696974;
    padding: 0 6px;
}
.tdBtnWrap{
    padding: 0 6px;
}
.tdDate{
    color: #696974;
    padding: 0 6px;
}
.tdDate span{
    font-weight: 600;
}
.tdType{
    color: #171725;
    padding: 0 6px;
}
.tdBtn{
    position:relative;
    margin-left: auto;
    width:40px;
    height:40px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    display:flex;
    cursor: pointer;
}
.tdBtn:hover,
.btnActive{
    position:relative;
    margin-left: auto;
    width:40px;
    height:40px;
    border: 1px solid #139DD9;
    transition: .2s;
    box-sizing: border-box;
    border-radius: 4px;
    display:flex;
    cursor: pointer;
}
.green{
    color: #00B191;
}
.black{
    color: #171725;
}

/*  DROP DOWN */

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
    display: flex;
    flex-direction: column;
    background-color: #f1f1f1;
    align-items: flex-start;
    width: auto;
    height: auto;
    background: #FFFFFF;
    box-shadow: 3px 3px 4px 1px rgba(68, 68, 79, 0.3);
    border-radius: 4px;
    min-width: 160px;
    z-index: 9999;
}
.dropDown{
    display: flex;
    position:absolute;
    top: 13px;
    right: 50px;
}
/* Links inside the dropdown */
.dropdownBlock{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9988;
}
.dropdownContent{
    width: auto;
    display: flex;
    flex-flow: column nowrap;
    padding: 5px;
    box-sizing: border-box;
}
.menuStep {
    display: flex;
    width: 100%;
    font-size: 14px;
    color: #171725;
    /* margin: 8px 0px 0px; */
    cursor: pointer;
    padding: 5px;
    box-sizing: border-box;
}
.menuStep:hover{
    background:#FAFAFB;
}
.noDisplay {
    display: none;
}
.svgIcon{
    fill:#92929d;
    fill-rule: evenodd;
}
a.link{
    text-decoration: none;
    color: #171725;
}
