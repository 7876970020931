.blockWrap{
    position: relative;
    width: 780px;
    height: auto;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    box-sizing: border-box;
}
.infoBlock {
    position: relative;
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0 0 0;
    padding: 24px;
    display: flex;
    flex-flow: column wrap;
}
.infoBlockInner {
    position: relative;
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 0 0 20px 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}

.noborder{
    border: none;
}
.infoBlock h3 {
    margin: 0 0 16px 0;
}
.infoBlock div:last-child{
    margin-bottom:0;
}
.infoBlockTitle{
    width: 100%;
    height: 40px;
    margin: 0 0 16px;
    display: flex;
    align-items: center;
}
.infoBlockInnerTitle{
    width: 80%;
    height: auto;
    margin: 0 0 8px;
}
.infoBlockInnerTitle h3{
    margin: 0;
}
.infoBlockTitle h3{
    margin: 0;
}
.blockTitle{
    width: 80%;
    height: auto;
    margin: 0 0 16px;
}
.generalInfo{
    border: none;
}
.content{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
}
.titleBlock {
    width:75%;
    font-weight: 600;
    font-size: 14px;
    margin: 8px 0 16px;
}
ul.infoBlockList{
    margin: 0 0 16px;
    padding-left: 20px;
}
ul.infoBlockList li{
    margin: 0 0 8px;
    font-size: 14px;
    position:relative;
}
ul.infoBlockList li::before{
    position:absolute;
    left:-20px;
    top:-2px;
    content: '●';
    font-size:18px;
    color:#92929D;
}
.downLoadAllBtn{
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    height: 40px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    color:#696974;
    display: flex;
    align-items: center;
    padding: 0px 16px;
    margin-left: auto;
    cursor: pointer;
}
.downLoadAllBtn:hover{
    border: 1px solid #139DD9;
    transition: .2s;
}
.bottomBtnBlock{
    width: 100%;
    height: 40px;
    margin: 10px 0 15px 0;
    display: flex;
    justify-content: center;
}
.sendBtn {
    width: auto;
    height: 40px;
    padding: 10px;
    cursor:pointer;
    border-radius: 4px;
    border: 1px solid #139DD9;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    width: 100px;
    height: 40px;
    color: #139DD9;
    margin: 20px 0 0 8px;
}
.draftBtnBlock {
    position: absolute;
    right: 24px;
    top: 20px;
    width: auto;
    height: 40px;
    display: flex;
    margin-left: auto;
}
.blueBtn{
    width: auto;
    height: 40px;
    padding: 10px;
    /* margin: 20px 0; */
    cursor:pointer;
    border: 1px solid #139DD9;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    background-color: #139DD9;
    color: #FAFAFB;
    display: flex;
    transition: all 0.2s;
}
.blueBtn:hover{
    background-color: transparent;
    color: #139DD9;
}
.editIcon, .deleteIcon {
    width: 38px;
    height: 38px;
    display: flex;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
}
.editIcon {
    border: 1px solid #E2E2EA;
}
.deleteIcon {
    border: 1px solid #FFA047;
}
.deleteIcon:hover{
    border: 1px solid #FFA047;
    background: #FFA047;
    transition: .2s;
}
.deleteIcon:hover .delIcon{
    fill:#FFFFFF;
    transition: .2s;
}
.editIcon:hover{
    border: 1px solid #00AFF0;
    background: #00AFF0;
    transition: .2s;
}
.editIcon:hover .svgIcon{
    fill:#FFFFFF;
    transition: .2s;
}
.nothing{
    width: 100%;
    height: 300px;
    display: flex;
    font-size: 28px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
}
.docListBlock{
    display: flex;
    flex-flow: column;
    gap: 15px;
    width: 100%;
}
