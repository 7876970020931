.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9999;
    overflow-y: scroll;
    overscroll-behavior: none;
}
.wrapper {
    width: 100%;
    /* height: 100%; */
    padding: 25px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popupBody {
    max-width: 518px;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
    border-radius: 10px;
    padding: 48px 24px 24px;
    position: relative;
}
.closeButton {
    width: 18px;
    height: 18px;
    background: none;
    border: none;
    position: absolute;
    top: 25px;
    right: 25px;
    transform:rotate(45deg);
    -ms-transform:rotate(45deg);
    -webkit-transform:rotate(45deg);
    cursor: pointer;
    transition: all 0.2s;
 }
.closeButton:before, .closeButton:after {
    content: "";
    position: absolute;
    z-index: -1;
    background-color: #92929D;
 }
 .closeButton:hover:before, .closeButton:hover:after {
     background-color: #424242;
 }

.closeButton:before {
    width: 2px;
    margin-top: -50%;
    margin-left: -1px;
    height: 100%;
 }

.closeButton:after {
    height: 2px;
    margin-left: -50%;
    margin-top: -1px;
    width: 100%;
}
.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #171725;
    margin-bottom: 20px;
}
.subtitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #171725;
    margin: 10px 0 16px;
}
.buttonWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}
.buttonCancel {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #696974;
    padding: 10px 16px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonCancel:hover {
    background-color: #E2E2EA;
}
.buttonCreate {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 10px 16px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    background-color: #139DD9;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonCreate:hover {
    background-color: transparent;
    color: #139DD9;
}
.buttonDelete {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #139DD9;
    padding: 10px 16px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonDelete:hover {
    background-color: #139DD9;
    color: #FFFFFF;
}
.koatuuListWrapper {
    position: relative;
    width: 100%;
}
.koatuuList {
    display: block;
    padding: 12px 0;
    margin: 0;
    box-shadow: 0px 1px 8px rgb(0 0 0 / 10%);
    border-radius: 10px;
    position: absolute;
    top: -10px;
    left: 0;
    max-width: 100%;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    background-color: #FFFFFF;
    z-index: 9999;
}
.koatuuList li {
    list-style-type: none;
    display: flex;
    margin-bottom: 15px;
    padding: 8px 20px;
    gap: 10px;
    cursor: pointer;
}
.koatuuList li:hover {
    background-color: #FAFAFB;
}
.koatuuList li:last-child {
    margin-bottom: 0;
    cursor: auto;
}
.koatuuList li:last-child:hover {
    background: none;
}
.koatuuId {
    color: #92929D;
    margin-left: auto;
}