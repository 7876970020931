.objTabItem{
    position:relative;
    display:flex;
    /* grid-template-columns: 313px 225px 216px 104px 104px 104px 86px; */
    /* grid-template-rows: auto; */
    background-color: #FFFFFF;
    border-radius: 4px;
    padding:12px 6px;
    margin-bottom: 8px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    transition: .2s;
    user-select: none;
}
.objTabItem:hover{
    border: 1px solid #E2E2EA;
    transition: .2s;
}

.tdName{
    display: flex;
    flex: 5;
    flex-flow: column;
    font-weight: 600;
    color: #171725;
    padding: 0 6px;
    /* cursor: pointer; */
}
a.aName{
    color:#171725;
}
.tdName p{
    font-weight: 600;
    font-size: 14px;
    color: #696974;
    margin: 8px 0 0;
}
a.link {
    font-weight: normal;
    font-size: 14px;
    color: #139DD9;
    text-decoration: underline;
    transition: all 0.2s;
}
a.link:hover {
    text-decoration: none;
}
.tdType{
    font-weight: 600;
    color: #696974;
    padding: 0 6px;
    display: flex;
    flex: 3;
    flex-flow: column nowrap;
    justify-content: space-between;
}
.tdStatus{
    display: flex;
    flex: 3;
    font-weight: 600;
    color: #696974;
    text-transform: uppercase;
    padding: 0 6px;
}
.properties{
    display: flex;
    flex: 2;
    flex-flow: column;
    color: #696974;
    padding: 0 6px;
}
.tdAction{
    display: flex;
    flex: 1;
    justify-content: flex-end;
    color: #696974;
    padding: 0 6px;
}
.tdDeclaration{
    display: flex;
    flex: 1;
    justify-content: flex-end;
    color: #696974;
    padding: 0 6px;
}
.tdBtnWrap{
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding: 0 6px;
}
.tdBtn{
    /* position:relative; */
    width: 40px;
    height: 40px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    cursor: pointer;
    transition: all 0.2s;
}
.tdBtn:hover {
    background-color: #E2E2EA;
}

.btnActive{
    width:40px;
    height:40px;
    background-color: #E2E2EA;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    cursor: pointer;
    transition: all 0.2s;
    box-shadow: inset 1px 3px 4px 0px rgb(68 68 79 / 30%)
}
.green{
    color: #00B191;
}
.black{
    color: #171725;
}
.obgId{
    color: #00AFF0;
    margin-top: 5px;
}
/*  DROP DOWN */

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
    display: flex;
    flex-direction: column;
    background-color: #f1f1f1;
    align-items: flex-start;
    width: auto;
    height: auto;
    background: #FFFFFF;
    box-shadow: 3px 3px 4px 1px rgba(68, 68, 79, 0.3);
    border-radius: 4px;
    min-width: 160px;
    z-index: 9999;
}
.dropDown{
    display: flex;
    position:absolute;
    top: 13px;
    right: 56px;
}
/* Links inside the dropdown */
.dropdownBlock{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9988;
}
.dropdownContent ul {
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.dropdownContent ul li {
    list-style-type: none;
    padding: 4px 12px;
}
.dropdownContent ul li a{
    display: block;
    font-size: 14px;
    color: #171725 !important;
    /* padding: 4px 12px; */
    cursor: pointer;
    transition: all 0.2s;
    text-decoration: none;
}
.dropdownContent ul li a:hover {
    background:#FAFAFB;
}
.svgIcon{
    fill:#92929d;
    fill-rule: evenodd;
}
.inactive{
    color: #E2E2EA;
    cursor: default;
}
/* Object Popup*/
.popUpBlock{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9988;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(1px) grayscale(60%);
}
.popUpOn{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9998;
}
.objectPopUp{
    position: absolute;
    /* margin: 0 auto; */
    width: 578px;
    min-height: 300px;
    background-color:#FFFFFF;
    height: auto;
    display:flex;
    flex-direction: column;
    padding: 24px;
    z-index: 9999;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    box-sizing: border-box;
}
.popUpTitle{
    font-size:24px;
    line-height: 24px;
    margin: 24px 0 0;
}
.popUpTitle span{
    font-size:18px;
    padding: 0 0 0 10px;
}
.objectPopUp ol {
    list-style-type: none; /* Убираем исходные маркеры */
    counter-reset:  item; /* Обнуляем счетчик списка */
}
.objectPopUp li{
    margin:6px 0;
    /* padding-left: 10px; */
}
.objectPopUp li:before {
    content: counter(item) ') '; /* Добавляем к числам скобку */
    counter-increment: item; /* Задаём имя счетчика */
    margin-left: -40px;
    margin-right: 5px;
}
.objectPopUp li:hover{
    color: #139DD9;
    text-decoration:underline;
    transition: .2s;
    cursor: pointer;
}
.objectPopUp li:last-child{
    margin-bottom: 0;
}
.closeBtn{
    position: absolute;
    top:24px;
    right:24px;
    cursor: pointer;
}
.closeBtn:hover .closeBtnSvg{
    fill: #139DD9;
    transition: .2s;
}

.lotStatus{
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}
.colorLightGrey{
    color: #92929D;
}
.colorDark{
    color: #171725;
}
.colorLightBrown{
    color: #A68800;
}
.colorGreen{
    color: #00B191;
}
.colorMeedllGrey{
    color: #696974;
}