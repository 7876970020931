.blockWrap{
    position: relative;
    width: 780px;
    height: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: 15px;
    padding-bottom: 15px;
    margin-top: 20px;
}
.infoBlock {
    width: 100%;
    height: auto;
    border: 1px solid #E2E2EA;
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    /* margin: 20px 0 0 0; */
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.generalInfo{
    border: none;
}
.infoBlockTitle{
    width: 100%;
    height: auto;
    margin: 0 0 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.infoBlockContent, .infoBlockOrgContent{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
}
.infoBlockOrgContent{
    gap: 20px;
}
.organizationWrap {
    width: 100%;
    /* height: auto; */
    /* border: 1px solid #E2E2EA; */
    background-color: #FFFFFF;
    box-sizing: border-box;
    border-radius: 4px;
    /* margin: 20px 0 0 0; */
    /* padding: 24px; */
    display: flex;
    flex-flow: row wrap;
}
.organizationTitle{
    width: 100%;
    font-weight: 600;
    font-size: 18px;
}
.infoBlock a{
    color: #139DD9;
}
.decisionItem{
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    border-radius: 8px;
    border: 1px solid #E2E2EA;
    padding: 20px 10px 20px 20px;
}
.relatedEntitiesList{
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
    /* padding-left: 25px; */
    width: 100%;
}
.contentList{
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
    width: 100%;
}
.sectionGray{
    width: 100%;
    height: auto;
    /* border: 1px solid #E2E2EA; */
    background-color: #FAFAFB;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 20px 0;
    padding: 24px;
    display: flex;
    flex-flow: row wrap;
}
.listEntitiesItemWrap{
    display: flex;
    width: 100%;
    border-radius: 8px;
    background-color: #FAFAFB;
    padding: 8px;
}