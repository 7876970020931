.myActionsTable .objTabItem{
    grid-template-columns: 300px 375px 156px 176px 86px 50px;
} 
.objTabItem{
    position:relative;
    display:grid;
    grid-template-columns: 300px 375px 156px 176px 86px 50px;
    grid-template-rows: auto;
    background-color: #FFFFFF;
    border-radius: 4px;
    padding:12px 6px;
    margin-bottom: 8px;
}

.tdName{
    font-weight: 600;
    color: #171725;
    padding: 0 6px;
    cursor: pointer;
}
.tdStat{
    font-weight: 600;
    color: #696974;
    padding: 0 6px;
}
.tdStatus{
    font-weight: 600;
    color: #696974;
    text-transform: uppercase;
    padding: 0 6px;
}
.tdRent{
    color: #696974;
    padding: 0 6px;
}
.tdAction{
    text-align:right;
    color: #696974;
    padding: 0 6px;
}
.tdDeclaration{
    text-align:right;
    color: #696974;
    padding: 0 6px;
}
.tdBtnWrap{
    padding: 0 6px;
}
.tdDate{
    color: #696974;
    padding: 0 6px;
}
.tdDate span{
    font-weight: 600;
}
.tdType{
    color: #171725;
    padding: 0 6px;
}
.tdBtn{
    position:relative;
    margin-left: auto;
    width: 40px;
    height: 40px;
    border: 1px solid #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    cursor: pointer;
    transition: all 0.2s;
}
.tdBtn:hover {
    background-color: #E2E2EA;
}

.btnActive{
    position:relative;
    margin-left: auto;
    width:40px;
    height:40px;
    background-color: #E2E2EA;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    cursor: pointer;
    transition: all 0.2s;
}
.green{
    color: #00B191;
}
.black{
    color: #171725;
}

/*  DROP DOWN */

/* Dropdown Content (Hidden by Default) */
.dropdownContent {
    display: flex;
    flex-direction: column;
    background-color: #f1f1f1;
    align-items: flex-start;
    width: auto;
    height: auto;
    background: #FFFFFF;
    box-shadow: 3px 3px 4px 1px rgba(68, 68, 79, 0.3);
    border-radius: 4px;
    min-width: 160px;
    z-index: 9999;
}
.dropDown{
    display: flex;
    position:absolute;
    top: 13px;
    right: 50px;
}
/* Links inside the dropdown */
.dropdownBlock{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9988;
}
.dropdownContent ul{
    width: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.dropdownContent ul li {
    list-style-type: none;
    display: block;
    font-size: 14px;
    color: #171725 !important;
    padding: 4px 12px;
    cursor: pointer;
    transition: all 0.2s;
    text-decoration: none;
}
.dropdownContent ul li a {
    /* display: block; */
    /* font-size: 14px; */
    color: #171725 !important;
    /* padding: 4px 12px; */
    cursor: pointer;
    transition: all 0.2s;
    text-decoration: none;
}
.dropdownContent ul li:hover{
    background:#FAFAFB;
}
.noDisplay {
    display: none;
}
.svgIcon{
    fill:#92929d;
    fill-rule: evenodd;
}
