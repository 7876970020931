.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrapper {
    background-color: #FFFFFF;
    box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
    border-radius: 4px;
    padding: 48px 24px 24px;
    max-width: 400px;
    width: 100%;
    position: relative;
}
.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #171725;
    margin-bottom: 15px;
}
.text {
    font-size: 14px;
    line-height: 19px;
    color: #171725;
    margin-bottom: 32px;
}
.buttonWrapper {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
}
.buttonCancel {
    border: 1px solid #E2E2EA;
    background-color: transparent;
    border-radius: 4px;
    padding: 10px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #696974;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonCancel:hover {
    background-color: #E2E2EA;
}
.buttonDelete {
    border: 1px solid #139DD9;;
    background-color: #139DD9;;
    border-radius: 4px;
    padding: 10px 16px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonDelete:hover {
    background-color: transparent;
    color: #139DD9;
}
.buttonClose {
    width: 18px;
    height: 18px;
    background: none;
    border: none;
    position: absolute;
    top: 25px;
    right: 25px;
    transform:rotate(45deg);
    -ms-transform:rotate(45deg);
    -webkit-transform:rotate(45deg);
    cursor: pointer;
    transition: all 0.2s;
 }

.buttonClose:before, .buttonClose:after {
    content: "";
    position: absolute;
    z-index: -1;
    background-color: #92929D;
 }
 .buttonClose:hover:before, .buttonClose:hover:after {
     background-color: #424242;
 }

.buttonClose:before {
    width: 2px;
    margin-top: -50%;
    margin-left: -1px;
    height: 100%;
 }

.buttonClose:after {
    height: 2px;
    margin-left: -50%;
    margin-top: -1px;
    width: 100%;
 }