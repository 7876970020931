.wrap {
    box-sizing: border-box;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 4px;
    padding: 20px;
    margin-top: 24px;
    margin-bottom: 24px;
}
.title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #171725;
    margin-bottom: 25px;
}
.relatedObjectWrapper {
    margin-bottom: 15px;
    margin-top: 15px;
}
.relatedObjectDescription {
    font-size: 10px;
    line-height: 13px;
    color: #696974;
}
.relatedObjectLink {
    display: block;
    font-size: 14px;
    line-height: 19px;
    text-decoration: underline;
    color: #00AFF0 !important;
}
.relatedObjectLink:hover {
    text-decoration: none;
}

.buttonWrapper {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
}
.buttonCancel {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #696974;
    padding: 10px 16px;
    border: 1px solid #E2E2EA;
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonCancel:hover {
    background-color: #E2E2EA;
}
.buttonSave {
    display: block;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #FFFFFF;
    padding: 10px 16px;
    border: 1px solid #139DD9;
    border-radius: 4px;
    background-color: #139DD9;
    cursor: pointer;
    transition: all 0.2s;
}
.buttonSave:hover {
    background-color: transparent;
    color: #139DD9;
}