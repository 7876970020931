.popup_wrap{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(0,0,0,.4);
    backdrop-filter: blur(1px) grayscale(60%);
}

.popup_message{
    padding: 40px;
    background-color: #fff;
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 360px;
    position: relative;
    border-radius: 8px;
}

.popup_message div{
    text-align: left;
    color: #6d6d6d;
}


.popUpExit{
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
}

.popUpExit:hover .closeSvg{
    fill: rgb(0, 175, 240);
    transition: .2s;
}



.popUpTitle{
    font-weight: 600;
    text-transform: uppercase;
    font-size: 18px;
    margin-bottom: 25px;
    text-align: center !important;
    color: #000 !important;
}

.inputWrap{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    color: #6d6d6d;
    width: 100%;
}
/* 
.inputLabel {
    margin-right: 30px;
} */

button.sso_button,
button.popup_message_button{
    border:1px solid rgb(0, 175, 240);
    outline: none;
    width: 100%;
    color: #fff !important;
    display: flex;
    font-weight: 600;
    justify-content: center;
    background-color: rgb(0, 175, 240);
    margin-top: 20px;
    padding: 10px;
    border-radius: 4px;
    transition: .2s;
    text-transform: uppercase;
    cursor: pointer;
}

button.sso_button:hover,
button.popup_message_button:hover{
    color: rgb(0, 175, 240) !important;
    border:1px solid rgb(0, 175, 240);
    background-color: rgb(255, 255, 255);
    transition: .2s;

}
