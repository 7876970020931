.NoPurchaseWrap {
    position: relative;
    width: 100%;
    height: 134px;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

 .NoPurchase {
    /* display: flex;
    justify-content: center; */
    width: 1200px;
} 

.NoPurchase .MyReqest {
    font-weight: 600;
}

.NoPurchase h3{
    font-family: sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    margin: 0px auto;
}

.NoPurchase p {
    font-family: sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    margin: 8px auto;
}

.ResetBtn {
    width: 187px;
    height: 32px;
    background: #139DD9;
    border-radius: 4px;    
    font-family: sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: center;
    color: #FAFAFB;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    margin: 18px auto 0px;
    cursor: pointer;
}

