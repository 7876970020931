.afterLogin{
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
}
.beforeLogin{
    width: 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
}
.login{
    display: inline-block;
    padding: 6px 10px;
    background: #00aff0;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 10px;
}
.exit{
    /*width: 1px;
    height: 1px;*/
    position: relative;
}
.login::after, .exit::after{
    content: ' ';
    position: absolute;
    left: -25px;
    width: 1px;
    top: 0;
    /*bottom: 17px;*/
    height: 45px;
    background: #cacdd1;
    z-index: 10;
}

.wrapLogin{
    margin-top: 15px;
    padding-left: 15px;
    /*border-left: 1px solid rgb(109, 109, 109);*/
    /*border-left: 1px solid #d9d9d9;*/
    margin-left: 15px;
    /*display: flex;*/
    width: 150px;
}

.korusne_img{
    display: flex;
    align-items: center;
    padding-left: 5px;
}

.korusne_title{
    font: 400 12px/14px "robotobold",Arial,sans-serif;
    color: #414141;
    text-align: left;
    text-transform: uppercase;
    margin-left: 5px;
    font-weight: 600;
}

.korusne_text{
    font: 400 11px/14px "robotolight",Arial,sans-serif;
    color: #666;
    text-align: left;
    text-transform: none;
    padding-left: 10px;
}

.signin{
    color: #585858;
}

a.signin:link, a.signin:visited, a.signin:hover, a.signin:active{
    text-decoration: none;
}

.register{
    font: 400 12px/14px "robotolight",Arial,sans-serif;
    color: #666;
    text-transform: lowercase;
}

.wrapLogin div div{
    cursor: pointer;
}

.svgLink{
    cursor: pointer;
    margin: 0 8px 0 0;
}
/*@media (min-width: 320px){
}

@media (min-width: 730px){
}

@media (min-width: 960px){
}

@media (min-width: 1280px){
}

@media (min-width: 1440px){
}

@media (min-width: 1600px){
}

@media (min-width: 1920px){
}*/

.singlUsePassword{
    margin: 0 0 0 20px;
    padding: 5px;
    border: 1px solid #000;
    width: auto;
    text-align: center;
}

.wrapBidPopUPEditDoc{
    position: absolute;
    top: 0;
    bottom: -100vh;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background-color: rgba(0, 0, 0, .4);
}

.wrapBidPopUPEditDoc_blank{
    background-color: #fff;
    padding: 20px;
}

.wrapBidPopUPEditDoc_text{
    text-align: center;
    margin-bottom: 20px;
}

.closeSingolPass{
    width: calc(100% - 20px);
    text-align: center;
}